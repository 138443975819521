<template>
  <VAppBar
    color="#000000"
    dark
    clipped-left
    app
  >

        <v-img
          class="mr-3 icon-plexi"
          src="@/assets/images/logoPlexigridYellow.png"
          max-height="40"
          max-width="40"

        />
 <VSpacer />
    <v-menu
      open-on-hover
    >
      <template v-slot:activator="{ props }">
        <v-img
          class="mr-3 user-button"
          src="@/assets/images/logoUserYellow.png"
          max-height="40"
          max-width="40"
          style="cursor:pointer"
          v-bind="props"
        />
      </template>

      <v-list>
        <v-list-item
          style="color:#F4C020; font-size:13px;text-align: left!important;"
        >
          {{ user.name }} <br> {{ user.email }} <br>
          {{ user.company }} <br> {{ user.department }}
        </v-list-item>
        <v-list-item
          style="color:#F4C020; font-size:13px;"
        >
          ------------------------------------------------
        </v-list-item>

        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :to="item.link"
        >
          <v-listItemTitle>{{ item.title }}</v-listItemTitle>
        </v-list-item>
      </v-list>
    </v-menu>
  </VAppBar>
</template>

<script>
import { getInfo } from '@/services/user'
import { mdiChevronDown } from '@mdi/js'
export default {
  name: 'NavBar',

  data: () => ({
    chevronDown: mdiChevronDown,
    tokenTrue: false,
    showMenu: false,
    showMenuNetwork: false,
    user: {
      name: null,
      email: null,
      company: null,
      department: null,
      admin: null
    }
  }),

  computed: {
    logged () {
      // return this.$sessionStorage.logged !== 'false'
      return true
    },
    items () {
      return [
        {
          title: 'Change Password',
          link: '/user-configuration'
        },
        { title: 'Logout', link: '/' }
      ]
    }
  },

  watch: {
    async logged () {
      // this.tokenTrue = sessionStorage.logged !== 'false'
      this.tokenTrue = true

      if (this.tokenTrue) {
        this.completeInfo()
      }
    }
  },
  mounted () {
    if (this.$route.name !== 'Login') {
      this.completeInfo()
    }
  },

  methods: {
    async completeInfo () {
      try {
        // const result = await getInfo()
        const result = await getInfo()

        this.user.name = result.first_name + ' ' + result.last_name
        this.user.email = result.email
        this.user.company = result.company
        this.user.department = result.department
        this.user.admin = true
        sessionStorage.uuid = result.uuid

        if (this.user.admin) {
          this.items.splice(0, 0, {
            title: 'Admin Panel',
            link: '/user-admin'
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style>
.v-sheet.v-app-bar.v-toolbar{
  z-index: 503;
}
.v-toolbar__content{
  padding:4px 6px !important;
}
.v-toolbar__title{
  padding-left:10px !important;
}
.v-tooltip__content {
  opacity: 1 !important;
  background-color:rgb(54, 54, 54)
}
.yellow{
  color:#F4C020;
}
.network-button{
  color:#F4C020;
  font-size:15px;
  cursor:pointer;
  margin-right: 15px;
}
.icon-plexi{
  margin-left: 5px;
}
</style>
