import { plexiRequest } from '..'

export const refreshToken = async (prevReqConfig) => {
  const body = new window.FormData()
  const refresh = window.sessionStorage.getItem('refresh')

  body.append('refresh', refresh)

  return plexiRequest({
    url: 'auth/api-token-refresh/',
    method: 'post',
    data: body
  }).then(async (data) => {
    window.sessionStorage.setItem('access', data.access)
    return await plexiRequest(prevReqConfig)
  })
}
export const login = async (email, password) => {
  const user = { email, password }
  return email && password &&
  plexiRequest({ method: 'post', data: user, url: '/auth/api-token/' }
  )
}
