<template>
<div class="dates-selector">
  <v-row>
    <v-img
      class="icon-chevron"
      src="@/assets/images/arrow_back.png"
      max-height="24"
      max-width="24"
      @click="dayBefore()"
    />
    <v-label cols="1" class="dates-labels">Start</v-label>
    <v-col cols="4">
    <v-text-field @change="changeDates()" type="date" density="compact" v-model="startDate" ></v-text-field>
    </v-col>
    <v-label cols="1" class="dates-labels">End</v-label>
    <v-col cols="4">
    <v-text-field @change="changeDates()" type="date" density="compact" v-model="endDate"></v-text-field>
    </v-col>
    <v-img
      class="icon-chevron"
      src="@/assets/images/arrow_forward.png"
      max-height="24"
      max-width="24"
      @click="dayAfter()"
    />
  </v-row>
</div>
</template>

<script>
import getDate from '@/mixins/getDate'
export default {
  mixins: [getDate],
  data: () => ({
    startDate: null,
    endDate: null
  }),
  methods: {
    changeDates () {
      if (this.startDate !== null && this.endDate !== null) {
        this.$store.dispatch('setElement', { path: 'energyPlot.timeStart', value: `${this.startDate}T00:00:00Z` })
        this.$store.dispatch('setElement', { path: 'energyPlot.timeEnd', value: `${this.endDate}T23:59:00Z` })
        this.$store.dispatch('setElement', { path: 'energyPlot.period', value: 'custom' })
      }
    },
    dayBefore () {
      this.startDate = this.addDays(this.startDate, -1)
      this.$store.dispatch('setElement', { path: 'energyPlot.timeStart', value: `${this.startDate}T00:00:00Z` })
      this.endDate = this.addDays(this.endDate, -1)
      this.$store.dispatch('setElement', { path: 'energyPlot.timeEnd', value: `${this.endDate}T23:59:00Z` })
      this.$store.dispatch('setElement', { path: 'energyPlot.period', value: 'custom' })
    },
    dayAfter () {
      this.startDate = this.addDays(this.startDate, 1)
      this.$store.dispatch('setElement', { path: 'energyPlot.timeStart', value: `${this.startDate}T00:00:00Z` })
      this.endDate = this.addDays(this.endDate, 1)
      this.$store.dispatch('setElement', { path: 'energyPlot.timeEnd', value: `${this.endDate}T23:59:00Z` })
      this.$store.dispatch('setElement', { path: 'energyPlot.period', value: 'custom' })
    },
    addDays (date, days) {
      const dateObj = new Date(date)
      dateObj.setDate(dateObj.getDate() + days)
      const newDate = dateObj.toISOString().slice(0, 10)
      return newDate
    }
  },
  mounted () {
    const fechaFormateada = this.getDate()
    this.startDate = fechaFormateada
    this.endDate = fechaFormateada
  }
}
</script>
<style>

.dates-selector{
  margin-top:-23px;
}
.v-text-field .v-field--no-label input, .v-text-field .v-field--active input {
    opacity: 1;
    font-size: 13px;
    margin-bottom: -10px;
}
.dates-labels{
  font-size: 13px;
  margin-top:-20px;
}
.v-tabs--density-default {
    --v-tabs-height: 30px;
}
.icon-chevron{
  margin-top:17px;
  margin-right: 3px;
  margin-left: -5px;
  cursor:pointer;

}
</style>
