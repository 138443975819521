<template>
<v-card width="100%">
    <v-tabs
      fixed-tabs
      v-model="tab"
      class="yellowPlexi"
    >
      <v-tab value="map" @click="clickTabMap()">Map</v-tab>
      <v-tab value="plant" @click="clickTabPlant()">Plant Management</v-tab>
      <v-tab value="vpp" @click="clickTabVpp()">VPP Management</v-tab>
    </v-tabs>

    <v-card-text>
      <v-window v-model="tab">
        <v-window-item value="map">
            <map-base
              :coordinates="coordinates"
              :data="data"
            />
        </v-window-item>

        <v-window-item value="plant">
          <plant-management
          />
        </v-window-item>

        <v-window-item value="vpp" >
          <virtual-power-plant-management
          />
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</template>

<script>
import MapBase from '@/components/Map/MapBase.vue'
import { mapState } from 'vuex'
import PlantManagement from '@/components/Plants/PlantManagement.vue'
import VirtualPowerPlantManagement from '@/components/Plants/VirtualPowerPlantManagement.vue'
export default {
  name: 'TabPlants',

  components: {
    MapBase,
    PlantManagement,
    VirtualPowerPlantManagement

  },
  props: {
    coordinates: {
      type: Array,
      default: () => ([40.31976674799456, -3.969568458524468])
    },
    data: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    tab: null
  }),

  computed: {
    ...mapState({
      tabPlants: (state) => state.tabPlants
    })
  },

  watch: {
    // whenever question changes, this function will run
    tabPlants () {
      this.tab = this.tabPlants
    }
  },
  mounted () {
  },
  methods: {
    clickTabMap () {
      this.$store.dispatch('setElement', { path: 'tabPlants', value: 'map' })
    },
    clickTabPlant () {
      this.$store.dispatch('setElement', { path: 'tabPlants', value: 'plant' })
    },
    clickTabVpp () {
      this.$store.dispatch('setElement', { path: 'tabPlants', value: 'vpp' })
    }
  }
}
</script>

<style scoped>
.v-card-text{
    line-height: 1.25rem;
    padding-top: 16px;
    padding-left: 1px;
    padding-right: 1px;
    padding-bottom: 1px;
}
.v-tab.v-tab {
    height: 25px;
}
</style>>
