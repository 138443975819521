<template>
  <v-icon :class="iconClass"><i class="icon-pv-solid"></i></v-icon>
</template>
<script>

export default {
  name: 'TabPlantPowerFlowSolarPanel.',
  props: {
    iconClass: {
      type: String,
      default: 'grey'
    }
  },
  data: () => ({
  }),
  mounted () {
    this.$store.dispatch('setElement', { path: 'plantPowerFlow.show', value: true })
  }
}
</script>
<style>
@import '../../../assets/main.css';
</style>
