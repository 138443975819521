<template>
  <v-navigation-drawer
    rail
    style="z-index:505"
    permanent
    clipped
    mini-variant
    color="#f4c020"
    app
      >

        <v-list >
          <v-list-item
            v-for="page in pages"
            :key="page.routerName"
            link
            :to="page.link"
          >
            <VImg
                :src="require(`@/assets/images/${page.image}`)"
                width="25"
              />
          </v-list-item>
        </v-list>

  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    name: 'SideBar',
    home: '/'
  }),

  computed: {
    logged () {
      return this.$sessionStorage.logged
    },

    pages () {
      return [
        {
          title: 'Plants',
          image: 'grid.png',
          link: '/plants'
        }
      ]
    }
  }
}
</script>

<style>
.v-navigation-drawer--clipped:not(.v-navigation-drawer--temporary)
:not(.v-navigation-drawer--is-mobile) {
    z-index: 502;
}

nav.v-navigation-drawer--clipped:not(.v-navigation-drawer--temporary)
:not(.v-navigation-drawer--is-mobile) {
    z-index: 502;
}

.tooltip{
  background-color:black;
  color:white;
}
</style>
