<template>
<v-card class="m-top-10">
    <div class="d-flex flex-row">
      <v-tabs v-model="tab" direction="vertical" class="yellowPlexi">
        <v-tab value="ev" v-if="dataDevices.has_ev>0">
          <v-icon class="icons" start><i class="icon-car-solid"></i></v-icon>
        </v-tab>
        <v-tab value="hp" v-if="dataDevices.has_hp>0">
          <v-icon class="icon-hp" start><i class="icon-heatpump-solid-1"></i></v-icon>
        </v-tab>
        <v-tab value="home" v-if="dataDevices.has_metter>0">
          <v-icon class="icons" start><i class="icon-heatpump-solid"></i></v-icon>
        </v-tab>
        <v-tab value="battery" v-if="dataDevices.has_battery>0">
          <v-icon class="icons" start><i class="icon-battery-solid"></i></v-icon>
        </v-tab>
        <v-tab value="pv" v-if="dataDevices.has_pv>0">
          <v-icon class="icons" start><i class="icon-pv-solid"></i></v-icon>
        </v-tab>
        <v-tab value="inverter" v-if="dataDevices.has_pv>0">
          <v-icon class="icons" start><i class="icon-inverter-solid"></i></v-icon>
        </v-tab>
      </v-tabs>
      <v-window v-model="tab" >
        <v-window-item value="ev">
          <v-card flat>

          </v-card>
        </v-window-item>
        <v-window-item value="hp">
          <v-card flat>

          </v-card>
        </v-window-item>
        <v-window-item value="home">
          <v-card flat class="tab-devices-device">
            <devices-home
              :data-devices="dataDevices"
              :data-devices-details="dataDevicesDetails.meter"
              :data-online="dataOnline">
            </devices-home>
          </v-card>
        </v-window-item>
        <v-window-item value="battery">
          <v-card flat class="tab-devices-device">
            <devices-battery
              :data-devices="dataDevices"
              :data-devices-details="dataDevicesDetails.battery"
              :data-online="dataOnline">
            </devices-battery>
          </v-card>
        </v-window-item>
        <v-window-item value="pv">
          <v-card flat class="tab-devices-device">
            <devices-solar-panel
              :data-devices="dataDevices"
              :data-devices-details="dataDevicesDetails.inverter"
              :data-online="dataOnline">
            </devices-solar-panel>
          </v-card>
        </v-window-item>
        <v-window-item value="inverter">
          <v-card flat class="tab-devices-device">
            <devices-inverter
              :data-devices="dataDevices"
              :data-devices-details="dataDevicesDetails.inverter"
              :data-online="dataOnline">
            </devices-inverter>
          </v-card>
        </v-window-item>
      </v-window>
    </div>
  </v-card>
</template>

<script>
import DevicesHome from './TabPlantDetailsDevices/DevicesHome.vue'
import DevicesBattery from './TabPlantDetailsDevices/DevicesBattery.vue'
import DevicesSolarPanel from './TabPlantDetailsDevices/DevicesSolarPanel.vue'
import DevicesInverter from './TabPlantDetailsDevices/DevicesInverter.vue'
export default {
  name: 'TabPlantDetailsDevices',

  components: {
    DevicesHome,
    DevicesBattery,
    DevicesSolarPanel,
    DevicesInverter
  },
  props: {
    dataDevices: {
      type: Object,
      default: () => ({})
    },
    dataDevicesDetails: {
      type: Object,
      default: () => ({})
    },
    dataOnline: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    tab: 'home'
  }),

  computed: {

  },
  watch: {

  },
  mounted () {

  },
  methods: {
    clickTabInfo () {

    }
  }
}
</script>

<style scoped>
.m-top-10{
  margin-top:10px;
}
.icons{
  font-size: 30px;
  margin-top: -0px;
}
.icon-hp{
  font-size: 20px;
  margin-left:-3px;
  margin-bottom: -10px;
}
.v-tab.v-tab {
    height: 41px;
    min-width: 30px!important;
    width: 43px!important;
}
.icon-grid{
  font-size: 35px;
  margin-left:-8px;
  margin-top:6px;
}
.scroll-vertical-tab-devices{
  height:28vh;
  overflow-y: scroll;
}
</style>
