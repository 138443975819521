<template>
    <div class="text-center">
      <v-card-subtitle class="yellowPlexi form-subtitle">Select plant</v-card-subtitle>
        <v-list
          width="100%"
        >
          <v-row>
            <v-col cols=12>
              <v-card-text>
                <v-text-field
                  v-model="searchTextPlants"
                  single-line
                  density="compact"
                  label="Filter plants"
                  append-inner-icon="mdi-filter"
                  class="filter-box"
                ></v-text-field>
              </v-card-text>
            </v-col>
          </v-row>
          <v-list-item-title>
            <v-row >
              <v-col cols=1>
                Select
              </v-col>
              <v-col cols =2>
                Name
              </v-col>
              <v-col cols =2>
                Code
              </v-col>
              <v-col cols =2>
                Capacity (kW)
              </v-col>
              <v-col cols =2>
                Location
              </v-col>
              <v-col cols =2>
                Date Created
              </v-col>
            </v-row>
          </v-list-item-title>
          <v-list-item class="items"
            v-for="plant in filteredplantsAvailables"
            :key="plant.plantName"
          >
            <v-row>
              <v-col cols=1>
                <v-checkbox
                v-model="plant.checked"
                density="compact"
                class="yellowPlexi"
                />
              </v-col>
               <v-col cols =2 class="col-account">
                <div class ="api-text yellowPlexi pointer" >
                {{ plant.plantName }}
                </div>
              </v-col>
              <v-col cols =2>
                <div class ="api-text yellowPlexi pointer" >
                {{ plant.plantCode }}
                </div>
              </v-col>
              <v-col cols =2>
                <div class ="api-text yellowPlexi pointer" >
                {{ plant.capacity }}
                </div>
              </v-col>
              <v-col cols =2>

              <v-tooltip :text="plant.plantAddress">
                <template v-slot:activator="{ props }">

                  <v-icon v-bind="props" class="yellowPlexi icon-account">mdi-map-marker-radius</v-icon>
                </template>
              </v-tooltip>

              </v-col>
              <v-col cols =2>
                <div class ="api-text yellowPlexi pointer" >
                {{ plant.gridConnectionDate}}
                </div>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
        <v-row>
          <v-col cols=6>
            <v-btn type="button" variant="tonal" @click="cancel"  block class="mt-2">Cancel</v-btn>
          </v-col>
          <v-col cols=6>
            <v-btn type="button" variant="tonal" @click="importPlants"  block class="mt-2">Import</v-btn>
          </v-col>

        </v-row>
    </div>

</template>

<script>

export default {
  name: 'AddPlantSelected',
  props: {
    plantsAvailables: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    searchTextPlants: ''

  }),
  mounted () {
  },
  computed: {

    filteredplantsAvailables () {
      const filterText = this.searchTextPlants.toLowerCase().trim()
      if (!filterText) {
        return this.plantsAvailables
      } else {
        return this.plantsAvailables.filter((plant) =>
          plant.uuid.substring(0, 6).toLowerCase().includes(filterText)
        )
      }
    }
  },
  methods: {
    async submit (event) {
      // const results = await event
      // alert(JSON.stringify(results, null, 2))
      this.dialog = true
      setTimeout(() => (this.sendDialog = true), 4000)
    },
    cancel () {
      this.$store.dispatch('setElement', { path: 'plantRegistration.showForm', value: false })
    },
    importPlants () {
      const plantsToImport = []
      for (let i = 0; i < this.plantsAvailables.length; i++) {
        if (this.plantsAvailables[i].checked) {
          const row = this.plantsAvailables[i]
          delete row.checked
          plantsToImport.push(row)
        }
      }
      console.log(plantsToImport)
      this.$store.dispatch('setElement', { path: 'plantRegistration.showForm', value: false })
    }
  }
}
</script>

<style scoped>
.v-info-title{
  font-size: 17px;
  margin-top:-15px;
}
.v-info-text{
  margin-top:2px!important;
}

.v-info-combo{
  margin-top:-10px!important;
  margin-bottom: -20px;
}

.card-info{
  margin-top:15px;
}
.info{
  margin-top: 10px;
}
.row-info{
  margin-bottom: -16px;
}
.BMSForm{
  padding: 20px;

}
.icon-dialog{
  padding: 40px;
margin-top: 0px;
}
.input-field{
  height: 70px;
}
.form-subtitle{
  font-size: 20px;
}
.icon-plant{
  margin-top: 5px;
  margin-left: 20px;
}
.icon-brand{
  cursor:pointer;
}
.api-text{
  font-size: 15px;
}
.api-check{
    display: flex;
  justify-content: center;
  align-items: center;
}
</style>
