<template>

<span class ="title yellowPlexi"> <b>VPP: {{plantName}} </b></span>
<v-divider></v-divider>
<div v-if ="loadingSelf.isVisible">
  <CMLoader
    :loading="loadingSelf.isVisible"
    :message="loadingSelf.message"
  />
</div>
<splitpanes
  v-else-if="loadingSelf.isVisible == false"
  horizontal
  :push-other-panes="false"
  style="height: 88vh">
  <pane
    min-size="40"
    max-size="40"
    size="40"
    >
    <splitpanes :push-other-panes="false">
      <pane
        min-size="20"
        max-size="20"
        size="20"
      >
        <tab-plant-summary
          v-if="plantSummaryVpp"
          :data="plantSummaryVpp"
        >
        </tab-plant-summary>
      </pane>
      <pane
        min-size="30"
        max-size="30"
        size="30"
      >
        <tab-plant-power-flow
          v-if="vpp && plantPlotdataVpp"
          :data-devices="vpp"
          :data-power-flow="plantPlotdataVpp"
          :id-svg-power-flow="idSvgPowerFlow"
          :svg-power-flow="svgPowerFlow"
        ></tab-plant-power-flow>
      </pane>
      <pane
        min-size="50"
        max-size="50"
        size="50"
      >
      <tab-plant-vpp-control-form>
      </tab-plant-vpp-control-form>
      </pane>
    </splitpanes>
  </pane>
  <pane
    min-size="60"
    max-size="60"
    size="60"
    >
    <tab-plant-details-plot-vpp
    v-if="plantPlotdataVpp"
    :data-plot-energy-vpp="plantPlotdataVpp"
    :loading-plot-vpp="loadingPlotVpp"
    >
    </tab-plant-details-plot-vpp>
  </pane>
</splitpanes>

</template>

<script>

import {
  getVPPDetailsPlot,
  getVPPSummary,
  getVPPDetails
} from '@/services/plants/plants'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import { mapState } from 'vuex'
import TabPlantSummary from './TabPlantSummary/TabPlantSummary.vue'
import TabPlantDetailsPlotVpp from './TabPlantDetails/TabPlantDetailsPlotVpp.vue'
import TabPlantVppControlForm from '../Plants/TabPlantVppControlForm.vue'
import TabPlantPowerFlow from './TabPlantPowerFlow/TabPlantPowerFlow.vue'
import getDate from '@/mixins/getDate'
import CMLoader from '@/components/Common/CMLoader.vue'

export default {
  name: 'PlantManagement',
  components: {
    Splitpanes,
    Pane,
    TabPlantSummary,
    TabPlantDetailsPlotVpp,
    TabPlantPowerFlow,
    CMLoader,
    TabPlantVppControlForm

  },
  mixins: [getDate],

  data: () => ({
    vpp: null,
    plantDataDevices: {},
    plantPlotdataVpp: null,
    plantPlotSavings: null,
    plantName: null,
    plantSummaryVpp: null,
    loadingSelf: {
      isVisible: false,
      message: 'Loading Data'
    },
    loadingPlotVpp: false,
    timerId: null,
    svgPowerFlow: 'power-flow-vpp-svg',
    idSvgPowerFlow: 'power-flow-vpp-svg-id'
  }),

  mounted () {
    if (this.$store.state.tabPlants === 'vpp') {
      this.showLoading(true)
      const currentDay = this.getDate()
      this.$store.dispatch('setElement', { path: 'energyPlot.agregate', value: '5m' })
      this.$store.dispatch('setElement', { path: 'energyPlot.timeStart', value: `${currentDay}T00:00:00Z` })
      this.$store.dispatch('setElement', { path: 'energyPlot.timeEnd', value: `${currentDay}T23:59:00Z` })
      this.$store.dispatch('setElement', { path: 'energyPlot.period', value: 'day' })
      setTimeout(() => { this.showLoading(false) }, 1000)
      this.timerId = this.startTimerUpdate(() => {})
    }
  },
  beforeUnmount () {
    this.stopTimer(this.timerId)
  },
  computed: {
    ...mapState({
      vppSelected: (state) => state.vppSelected.uuid,
      period: (state) => state.energyPlot.period,
      tabPlants: (state) => state.tabPlants
    })
  },
  watch: {
    // whenever question changes, this function will run
    vppSelected () {
      if (this.$store.state.tabPlants === 'vpp') {
        this.showLoading(true)
        this.setDateToday()
        this.getData()
        this.getDataPlot()
        this.getDataSummary()
        setTimeout(() => { this.showLoading(false) }, 300)
      }
    },
    tabPlants () {
      if (this.$store.state.tabPlants === 'vpp') {
        this.showLoading(true)
        this.setDateToday()
        this.getData()
        this.getDataPlot()
        this.getDataSummary()

        setTimeout(() => { this.showLoading(false) }, 300)
      }
    },
    period () {
      if (this.$store.state.tabPlants === 'vpp') {
        this.getDataPlot()
        this.getData()
        this.getDataSummary()
      }
    }
  },

  methods: {
    async getData () {
      try {
        const vppSelected = this.$store.state.vppSelected.uuid

        const vpp = await getVPPDetails(vppSelected)
        this.plantName = vpp[0].name
        this.vpp = vpp[0]
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    async getDataPlot () {
      try {
        this.loadingPlot = true
        const vppSelected = this.$store.state.vppSelected.uuid

        const timeStart = this.$store.state.energyPlot.timeStart
        const timeEnd = this.$store.state.energyPlot.timeEnd
        const agregate = this.$store.state.energyPlot.agregate

        const timeClient = this.getLocalHourFormated()

        const plantPlotdataVpp = await getVPPDetailsPlot(vppSelected, timeStart, timeEnd, agregate, timeClient)
        setTimeout(() => { this.$store.dispatch('setElement', { path: 'plantPowerFlow.changeData', value: true }) }, 800)
        this.$store.dispatch('setElement', { path: 'plantPowerFlow.calculating', value: false })
        this.plantPlotdataVpp = plantPlotdataVpp
        this.$store.dispatch('setElement', { path: 'energyPlot.period', value: 'recustom' })
        setTimeout(() => { this.loadingPlot = false }, 20)
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    async getDataSummary () {
      try {
        const vppSelected = this.$store.state.vppSelected.uuid
        const plantSummaryVpp = await getVPPSummary(vppSelected)
        this.plantSummaryVpp = plantSummaryVpp
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    showLoading (show) {
      this.loadingSelf.isVisible = show
    },
    getLocalHourFormated () {
      const dateObject = new Date()
      const year = dateObject.getFullYear()
      const month = String(dateObject.getMonth() + 1).padStart(2, '0')
      const day = String(dateObject.getDate()).padStart(2, '0')
      const hours = String(dateObject.getHours()).padStart(2, '0')
      const minutes = String(dateObject.getMinutes()).padStart(2, '0')
      const seconds = String(dateObject.getSeconds()).padStart(2, '0')

      // Crear la cadena en formato ISO8601
      const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`

      return isoString
    },
    setDateToday () {
      const todayStart = this.getLocalHourFormated().substr(0, 10)
      this.$store.dispatch('setElement', { path: 'energyPlot.timeStart', value: todayStart + 'T00:00:00Z' })
      this.$store.dispatch('setElement', { path: 'energyPlot.timeEnd', value: todayStart + 'T23:59:00Z' })
    },
    startTimerUpdate (callback, interval = 300000) {
      const timerId = setInterval(() => {
        this.showLoading(true)
        this.setDateToday()
        this.getDataPlot()
        this.getDataSummary()
        setTimeout(() => { this.showLoading(false) }, 300)
        if (callback) {
          callback()
        }
      }, interval)

      return timerId
    },
    stopTimer (timerId) {
      clearInterval(timerId)
    }

  }
}
</script>

<style scoped>
.login {
  padding: 2rem;
}
.title {
  text-align: center;
  font-size: 18px;
    display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 4px;
}
.form {
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  min-width: 350px;
  max-width: 100%;
  background: #1e1e1e;
  border-radius: 5px;
  padding: 40px;
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.3);
}

.error {
  margin: 1rem 0 0;
  color: #ff4a96;
}
.msg {
  margin-top: 3rem;
  text-align: center;
}
.centerPlexi{
  margin-left: 34%;
  margin-bottom: 15px;
}
.splitpanes__pane {
  display: flex;
  justify-content: center;

  color: rgba(255, 255, 255, 0.6);
  font-size: 1em;
}

</style>
