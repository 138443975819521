<template>
  <div class="circle"></div>
</template>
<script>

export default {
  name: 'TabPlantPowerFlowHub',
  props: {
    iconClass: {
      type: String,
      default: 'grey'
    }
  },
  data: () => ({
  })
}
</script>
<style>
@import '../../../assets/main.css';
  .circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(53,152,218, 0.4);
    z-index: 1;
    border: 2px solid rgba(244, 192, 32, 0.7);
    animation: border-animation 3s infinite linear;
  }
  @keyframes border-animation {
    0% {
      border-color: transparent;
    }
    25% {
      border-color: gold;
    }
    50% {
      border-color: transparent;
    }
    75% {
      border-color: gold;
    }
    100% {
      border-color: transparent;
    }
  }
</style>
