<template>
  <!-- VIEW ICONS DEVICES -->
  <div>
    <div
      v-for="plant in plants"
      v-bind:key="plant.id"
      >
      <div v-if="plant.checked">
         <div v-if="plant.has_ev > 0 || plant.has_hp > 0 || plant.has_battery > 0 || plant.has_pv > 0 ">
          <plant-icon
            :location="[plant.latitude, plant.longitude]"
            :uuid="plant.uuid"
            :name="plant.name"
            :ev-status="plant.has_ev"
            :hp-status="plant.has_hp"
            :battery-status="plant.has_battery"
            :pv-status="plant.has_pv"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlantIcon from './PlantIcon.vue'

export default {
  name: 'PlantComponent',

  components: {

    PlantIcon
  },
  props: {
    plants: {
      type: Array,
      default: () => ([])
    },
    zoom: {
      type: Number,
      default: 0
    }
  },
  data: () => ({

  }),

  mounted () {
  },
  methods: {
    clickIcon (plant) {
    }
  }
}
</script>

<style>
@import '../../assets/css/plexi-derms.css';

.v-navigation-drawer--clipped:not(.v-navigation-drawer--temporary)
:not(.v-navigation-drawer--is-mobile) {
    z-index: 502;
}

nav.v-navigation-drawer--clipped:not(.v-navigation-drawer--temporary)
:not(.v-navigation-drawer--is-mobile) {
    z-index: 502;
}

.tooltip{
  background-color:black;
  color:white;
}

.square {
  width: 34px;
  height: 35px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}
.square::before {
  content: "";
  position: absolute;
  top: -29px;
  left: -14px;
  right: -19px;
  bottom: -3px;
  border-radius: 50%;
  border-color:yellow;
  background-color: rgba(53,152,218, 0.4);
  z-index: -1;
  border: 2px solid rgba(244, 192, 32, 0.7);
  animation: border-animation 3s infinite linear;
}
@keyframes border-animation {
    0% {
      border-color: transparent;
    }
    25% {
      border-color: gold;
    }
    50% {
      border-color: transparent;
    }
    75% {
      border-color: gold;
    }
    100% {
      border-color: transparent;
    }
  }

.square-circle {
  background-color: transparent;
  border-radius: 50%;
  width: 0px;
  height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

}

</style>
