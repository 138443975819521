<template>
  <div class="scroll-vertical-tab-devices">
    <v-table density="compact">
      <tbody>
        <tr class="online">
          <td>{{ dataDevicesDetails.temperature.frontend_name }}</td>
          <td>{{ dataDevicesDetails.temperature.value + ' ' + dataDevicesDetails.temperature.unit}}</td>
        </tr>
        <tr class="online">
          <td>{{ dataDevicesDetails.powerFactor.frontend_name }}</td>
          <td>{{ dataDevicesDetails.powerFactor.value + ' ' + dataDevicesDetails.powerFactor.unit}}</td>
        </tr>
        <tr class="online">
          <td>{{ dataDevicesDetails.outFreq.frontend_name }}</td>
          <td>{{ dataDevicesDetails.outFreq.value + ' ' + dataDevicesDetails.outFreq.unit}}</td>
        </tr>
        <tr class="online">
          <td>{{ dataDevicesDetails.efficiency.frontend_name }}</td>
          <td>{{ dataDevicesDetails.efficiency.value + ' ' + dataDevicesDetails.efficiency.unit}}</td>
        </tr>
        <tr class="online">
          <td>{{ dataDevicesDetails.InverterStatus.frontend_name }}</td>
          <td>{{ dataDevicesDetails.InverterStatus.value + ' ' + dataDevicesDetails.InverterStatus.unit}}</td>
        </tr>
        <tr class="online">
          <td>{{ dataDevicesDetails.InverterState.frontend_name }}</td>
          <td>{{ dataDevicesDetails.InverterState.value + ' ' + dataDevicesDetails.InverterState.unit}}</td>
        </tr>

        <tr class="static-data" v-if="dataDevices.devices.inverter.length>0">
          <td>Brand</td>
          <td v-if="dataDevices">{{ dataDevices.devices.inverter[0].brand}}</td>
        </tr>
        <tr class="static-data" v-if="dataDevices.devices.inverter.length>0">
          <td>Model</td>
          <td v-if="dataDevices">{{ dataDevices.devices.inverter[0].model}}</td>
        </tr>
        <tr class="static-data" v-if="dataDevices.devices.inverter.length>0">
          <td>Rated Power</td>
          <td v-if="dataDevices">{{ dataDevices.devices.inverter[0].rated_power}} kW</td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script>

export default {
  name: 'DevicesSolarPanel',

  components: {
  },
  props: {
    dataDevices: {
      type: Object,
      default: () => ({})
    },
    dataDevicesDetails: {
      type: Object,
      default: () => ({})
    },
    dataOnline: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({

  }),

  computed: {

  },
  mounted () {
    // console.log(this.dataDevices)
  },
  methods: {

  }
}
</script>

<style>

</style>
