<template>
    <v-card width="100%" v-if=showFormControlBatManual>
      <TabPlantDetailsControlBMSForm/>
    </v-card>
    <v-card width="100%" v-else>
      <v-tabs
        fixed-tabs
        v-model="tab"
        class="yellowPlexi"
      >
        <v-tab value="info" @click="clickTabInfo()">Info</v-tab>
        <v-tab value="devices">Devices</v-tab>
        <v-tab value="alarms">Alarms</v-tab>
        <v-tab value="control">Control</v-tab>
      </v-tabs>

      <v-card-text>
        <v-window v-model="tab">
          <v-window-item value="info">
            <tab-plant-details-info
              :data="dataDevices">
            </tab-plant-details-info>
          </v-window-item>

          <v-window-item value="devices">
            <tab-plant-details-devices
              :data-devices="dataDevices"
              :data-devices-details="dataDevicesDetails"
              :data-online="dataPowerFlow">
            </tab-plant-details-devices>
          </v-window-item>

          <v-window-item value="alarms" >
            Alarms
          </v-window-item>

          <v-window-item value="control">
            <TabPlantDetailsControlBMS/>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
</template>

<script>
import TabPlantDetailsInfo from './TabPlantDetailsInfo.vue'
import TabPlantDetailsControlBMS from './TabPlantDetailsControlBMS.vue'
import TabPlantDetailsControlBMSForm from './TabPlantDetailsControlBMSForm.vue'
import TabPlantDetailsDevices from './TabPlantDetailsDevices.vue'
import { mapState } from 'vuex'

export default {
  name: 'TabPlantDetails',

  components: {
    TabPlantDetailsInfo,
    TabPlantDetailsControlBMS,
    TabPlantDetailsControlBMSForm,
    TabPlantDetailsDevices

  },
  props: {
    dataDevices: {
      type: Object,
      default: () => ({})
    },
    dataDevicesDetails: {
      type: Object,
      default: () => ({})
    },
    dataPowerFlow: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    tab: null,
    showFormControlBatManual: false
  }),

  computed: {
    ...mapState({
      controlBatManual: (state) => state.showFormControlBatManual
    })
  },
  watch: {
    // whenever question changes, this function will run
    controlBatManual () {
      this.showFormControlBatManual = this.$store.state.showFormControlBatManual
    }
  },
  mounted () {
  },
  methods: {
    clickTabInfo () {

    }
  }
}
</script>

<style scoped>
.v-tab.v-tab {
    height: 25px;
}
</style>
