<template>
<v-card width="100%">
    <v-tabs
      v-model="tab"
      class="yellowPlexi"
    >
      <v-tab value="energy" @click="clickTabEnergy()">Energy Management</v-tab>
      <v-tab value="soc" >State of Charge</v-tab>
      <v-tab value="savings" disabled>Savings</v-tab>
    </v-tabs>

    <v-card-text>
      <v-window v-model="tab">
        <v-window-item value="energy">
          <tab-plant-details-plot-energy
            v-if="dataPlotEnergy"
            :data="dataPlotEnergy"
            :loading-plot="loadingPlot"
          >
          </tab-plant-details-plot-energy>
        </v-window-item>
        <v-window-item value="soc">
          <TabPlantDetailsPlotSOC
            v-if="dataPlotEnergy"
            :data="dataPlotEnergy"
            :loading-plot="loadingPlot"
          >
          </TabPlantDetailsPlotSOC>
        </v-window-item>
        <v-window-item value="savings">
          <tab-plant-details-plot-savings
            v-if="dataPlotSavings"
            :data="dataPlotSavings"
          >
          </tab-plant-details-plot-savings>
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</template>

<script>
import TabPlantDetailsPlotEnergy from '../TabPlantEnergyPlot/TabPlantDetailsPlotEnergy.vue'
import TabPlantDetailsPlotSavings from '../TabPlantEnergyPlot/TabPlantDetailsPlotSavings.vue'
import TabPlantDetailsPlotSOC from '../TabPlantEnergyPlot/TabPlantDetailsPlotSOC.vue'
export default {
  name: 'TabPlantDetailsPlot',

  components: {
    TabPlantDetailsPlotEnergy,
    TabPlantDetailsPlotSavings,
    TabPlantDetailsPlotSOC

  },
  props: {
    dataPlotEnergy: {
      type: Object,
      default: () => ({})
    },
    dataPlotSavings: {
      type: Object,
      default: () => ({})
    },
    loadingPlot: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    tab: null
  }),

  computed: {

  },

  watch: {
  },
  mounted () {

  },
  methods: {
    clickTabEnergy () {

    }
  }
}
</script>

<style scoped>
.v-tab.v-tab {
    height: 25px;
}
</style>
