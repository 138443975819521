export const SET_ELEMENT = 'SET_ELEMENT'
export const RESET_STATE = 'RESET_STATE'

export const THEME_STYLES = {
  dark: 'dark',
  light: 'light'
}

export const COUNTRY_CODES = {
  'Europe/Madrid': 'spain',
  'Europe/Stockholm': 'sweden'
}

export const ICONS = {
  PV: 'pv-yellowcircle-solid.svg',
  EV: 'car-yellowcircle-solid.svg',
  HP: 'heatpump-yellowcircle-solid.svg',
  Phase: 'changephase-yellowcircle-solid.svg',
  RemoveUser: 'deleteuser-yellowcircle-solid.svg',
  More: 'more-yellowcircle-solid.svg',
  Switch: 'switchfuse-yellowcircle-solid.svg'
}

export const TYPE_VALIDATIONS = {
  required: (value) => !!value || 'Item is required',
  min: (min) => (value) => !!(value >= min) || `Item should be equal or greater that ${min}`,
  minMax: (min, max) => (value) => !!(value >= min && value <= max) || `Item should be between ${min} and ${max}`,
  integer: (value) => !!(value % 1 === 0) || 'Item should be integer',
  notSpecial: (value) => (value && ![...value.matchAll(/[^a-zA-Z0-9-ZäöåÄÖÅ ]/g)].length) || 'Special characters is not allowed'
}

const {
  required, min, minMax, integer, notSpecial
} = TYPE_VALIDATIONS

export const ELEMENT_TYPES = {
  C: 'stations',
  TD: 'transformers',
  F: 'feeders'
}

export const VALIDATIONS = {
  ndevs: [required, min(1), integer],
  rcp: [required],
  c: [required, min(0)],
  pf: [required, minMax(0.01, 1)],
  newProjectName: [required, notSpecial]
}

export const INITIAL_STATE = () => ({
  theme: 'customDarkTheme',
  logged: false,
  devicesSelected: {
    ev: true,
    hp: true,
    meter: false,
    battery: true,
    pv: true
  },
  tabPlants: 'map',
  vppSelected: {
    uuid: null,
    name: null
  },
  plantSelected: null,
  energyPlot: {
    period: null,
    timeStart: null,
    timeEnd: null,
    agregate: null,
    typePlot: 'scatter'
  },
  plantPowerFlow: {
    show: false,
    calculating: false,
    changeData: false
  },
  controlBMS: false,
  controlBatManual: false,
  showFormControlBatManual: false,
  plantRegistration: {
    showForm: false,
    addPlantProvider: false,
    addPlantCredentials: false,
    addPlantSelected: false,
    addPlantImported: false,
    provider: null,
    authentication: null,
    accountSelected: null

  }
})
