<template>
  <v-card width="100%" class="info">
    <div class="card-info">
      <v-row class="row-info">
        <v-card-title class="v-info-title yellowPlexi">Owner</v-card-title>
        <v-card-text class="v-info-text class-owner" v-if="data.owner">
          {{data.owner.last_name + " " + data.owner.first_name}}
        </v-card-text>
        <v-card-text class="v-info-text class-owner" v-else>
          -
        </v-card-text>
      </v-row>
      <v-row class="row-info">
        <v-card-title class="v-info-title yellowPlexi">ID</v-card-title>
        <v-card-text class="v-info-text class-id" v-if="data.owner">
          {{data.owner.DNI}}
        </v-card-text>
        <v-card-text class="v-info-text class-id" v-else>
          -
        </v-card-text>
      </v-row>
      <v-row class="row-info">
        <v-card-title class="v-info-title yellowPlexi">CUPS</v-card-title>
        <v-card-text class="v-info-text class-cups" v-if="data.cups!=='[]'">
          {{data.cups}}
        </v-card-text>
        <v-card-text class="v-info-text class-cups" v-else>
          -
        </v-card-text>
      </v-row>
      <v-row class="row-info">
        <v-card-title class="v-info-title yellowPlexi">Address</v-card-title>
        <v-card-text class="v-info-text class-address" v-if="data.owner">
          {{data.owner.address}}
        </v-card-text>
        <v-card-text class="v-info-text class-address" v-else>
          -
        </v-card-text>
      </v-row>
      <v-row class="row-info">
        <v-card-title class="v-info-title yellowPlexi">Coordinates</v-card-title>
        <v-card-text class="v-info-text class-coordinates">
          {{data.latitude + ", " + data.longitude}}
        </v-card-text>
      </v-row>
      <v-row class="row-info">
        <v-card-title class="v-info-title yellowPlexi">Flex Node</v-card-title>
        <v-card-text class="v-info-text class-flex">
          -
        </v-card-text>
      </v-row>
      <v-row class="row-info">
        <v-card-title class="v-info-title yellowPlexi">Contracted Power (kW)</v-card-title>
        <v-card-text class="v-info-text class-hired" v-if="data.owner">
          {{data.owner.hired_power}}
        </v-card-text>
        <v-card-text class="v-info-text class-hired" v-else>
          -
        </v-card-text>
      </v-row>
      <v-row class="row-info">
        <v-card-title class="v-info-title yellowPlexi">Tariff</v-card-title>
        <v-card-text class="v-info-text class-tariff" v-if="data.owner">
          {{data.owner.tariff}}
        </v-card-text>
        <v-card-text class="v-info-text class-tariff" v-else>
          -
        </v-card-text>
      </v-row>
    </div>
  </v-card>
</template>

<script>

export default {
  name: 'TabPlantDetailsInfo',

  components: {

  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({

  }),

  computed: {

  },

  watch: {
  },
  mounted () {
  },
  methods: {
    clickTabInfo () {

    }
  }
}
</script>

<style scoped>
.v-info-title{
  font-size: 14px;
  margin-top:-20px;
}
.v-info-text{
  margin-top:-6px!important;
  text-align: left;

}

.v-info-combo{
  margin-top:-10px!important;
  margin-bottom: -20px;
}

.card-info{
  margin-top:15px;
}
.info{
  margin-top: 10px;
}
.row-info{
  margin-bottom: -16px;
}

.class-owner{
  margin-left: 100px;
}
.class-id{
  margin-left: 130px;
}
.class-cups{
  margin-left: 105px;
}
.class-address{
  margin-left:90px;
}
.class-coordinates{
  margin-left: 65px;
}
.class-flex{
  margin-left: 80px;
}
.class-hired{
  margin-left: -12px;
}
.class-tariff{
  margin-left: 110px;
}
</style>
