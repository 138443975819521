import { createStore } from 'vuex'
import {
  set
} from 'lodash'
import {
  INITIAL_STATE,
  SET_ELEMENT,
  RESET_STATE
} from './constants'

export default createStore({
  state: INITIAL_STATE(),
  getters: {
  },
  mutations: {
    SET_ELEMENT (state, { path, value, root }) {
      set(state, path, value)
      root && set(state, root, { ...state[root] })
    },
    RESET_STATE (state) {
      Object.assign(state, INITIAL_STATE())
    }
  },
  actions: {
    setElement ({ commit }, payload) {
      commit(SET_ELEMENT, payload)
    },
    resetState ({ commit }) {
      commit(RESET_STATE)
    }
  },
  modules: {
  }
})
