<template>
  <v-app>
    <v-main>
      <div v-if="!inLogin">
        <nav-bar />
        <side-bar />
      </div>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from '@/components/Layout/NavBar.vue'
import SideBar from './components/Layout/SideBar.vue'

export default {
  name: 'App',

  components: {
    NavBar,
    SideBar
  },
  data: () => ({
    inLogin: false
  }),

  metaInfo: {
    title: 'ADMS',
    titleTemplate: 'ADMS | %s'
  },
  computed: {
    view () {
      return this.$route.name
    }
  },

  watch: {
    async view () {
      this.inlogin()
    }
  },
  mounted () {
    this.inlogin()
  },
  methods: {
    inlogin () {
      if (this.$route.name === 'Login') {
        this.inLogin = true
      } else {
        this.inLogin = false
      }
    }
  }
}
</script>

<style >
#app {

  font-family: 'Faktum Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
.yellowPlexi{
  color:#f4c020
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: inset 0 0 5px #333;
  box-shadow: inset 0 0 5px #111;
  border:1px solid #333;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border:1px solid #333;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777;
}

</style>
