<template>
  <div class="text-center">
    <v-progress-circular
      indeterminate
      :size="100"
      :width="15"
      color="amber">
      </v-progress-circular>
  </div>
</template>

<style scoped>
  .v-progress-circular {
    margin: 1rem;
  }
</style>
