<template>
  <v-card width="100%" >
    <div class="title-summary yellowPlexi"><span>Last Month</span></div>
    <v-card
      class="mx-auto icon-size "
      width="100%"
      prepend-icon="mdi-cog"
      v-if="data.pvPowerY"
    >
      <template v-slot:title >
        <span class="summary-title" style="text-align:left;" > {{data.pvPowerY.frontend_name}} </span>
      </template>

      <v-card-text class=" yellowPlexi summary-data">
        {{data.pvPowerY.value.toFixed(2).toString().replace(".", ",") + ' ' + data.pvPowerY.unit}}
      </v-card-text>
    </v-card>
    <v-card
      class="mx-auto icon-size"
      width="100%"
      prepend-icon="mdi-home"
      v-if="data.meterPowerY"
    >
      <template v-slot:title >
        <span class="summary-title"> {{data.meterPowerY.frontend_name}} </span>
      </template>

      <v-card-text class=" yellowPlexi summary-data">
        {{data.meterPowerY.value.toFixed(2).toString().replace(".", ",") + ' ' + data.meterPowerY.unit}}
      </v-card-text>
    </v-card>
    <v-card
      class="mx-auto icon-size"
      width="100%"
      prepend-icon="mdi-car"
      v-if="data.evPowerY"
    >
      <template v-slot:title >
        <span class="summary-title"> {{data.evPowerY.frontend_name}}  </span>
      </template>

      <v-card-text class=" yellowPlexi summary-data">
        {{data.evPowerY.value.toFixed(2).toString().replace(".", ",") + ' ' + data.evPowerY.unit}}
      </v-card-text>
    </v-card>
    <v-card
      class="mx-auto icon-size"
      width="100%"
      prepend-icon="mdi-heat-pump-outline"
      v-if="data.hpPowerY"
    >
      <template v-slot:title >
        <span class="summary-title"> {{data.hpPowerY.frontend_name}}  </span>
      </template>

      <v-card-text class=" yellowPlexi summary-data">
        {{data.hpPowerY.value.toFixed(2).toString().replace(".", ",") + ' ' + data.hpPowerY.unit}}
      </v-card-text>
    </v-card>
    <v-card
      class="mx-auto icon-size-battery"
      width="100%"
      prepend-icon="mdi-car-battery"
      v-if="data.batteryPowerY"
    >
      <template v-slot:title >
        <span class="summary-title"> {{data.batteryPowerY.frontend_name}} </span>
      </template>

      <v-card-text class=" yellowPlexi summary-data">
        {{data.batteryPowerY.value.toFixed(2).toString().replace(".", ",") + ' ' + data.batteryPowerY.unit}}
      </v-card-text>
    </v-card>
        <v-card
      class="mx-auto icon-size"
      width="100%"
      prepend-icon="mdi-currency-usd"
      v-if="data.savingsY"
    >
      <template v-slot:title >
        <span class="summary-title"> {{data.savingsY.frontend_name}} </span>
      </template>

      <v-card-text class=" yellowPlexi summary-data">
       {{data.savingsY.value.toString().replace(".", ",") + ' ' + data.savingsY.unit}}
      </v-card-text>
    </v-card>

  </v-card>
</template>

<script>

export default {
  name: 'TabPlantSummary',

  components: {

  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({

  }),

  computed: {

  },

  watch: {
  },
  mounted () {

  },
  methods: {
    clickTabInfo () {

    }
  }
}
</script>

<style>
.v-info-title{
  font-size: 17px;
  margin-top:-15px;
}

.v-info-combo{
  margin-top:-10px;
  margin-bottom: -20px;
}

.card-info{
  margin-top:15px;
}

.summary-data{
  font-size: 18px;
  margin-top:-15px;
  margin-bottom:-15px;
  margin-left: 55px;
  text-align: left;
}
.summary-title{
  font-size: 12px;
  text-align: left !important;;
}
.icon-size{
  font-size: 30px;
  margin-bottom: -1px;
}
.icon-size-battery{
  font-size: 25px;
}
.v-card-title {
  margin-top:-13px;
  text-align: left;
}
.v-card-text {
  margin-top:-26px;
}
.title-summary{
  display: flex;
  justify-content: center;
  margin-top: 5px;
  font-size: 17px!important;
}
</style>
