<template>
    <splitpanes horizontal>
      <pane
          size="45"
      >
        <v-list
          width="100%"
        >
          <v-card-text>
            <v-text-field
              :loading="loading"
              density="compact"
              variant="solo"
              label="Search by... (City, CP)"
              append-inner-icon="mdi-magnify"
              single-line
              hide-details
              class="search-box"
              @click:append-inner="onClick"
            ></v-text-field>
          </v-card-text>
          <v-list-item-title class="titlePane yellowPlexi">Assets</v-list-item-title>

          <v-row>
            <v-col cols=1>
              <v-checkbox
              v-model="checkedAllDevices"
              @click="clickAllDevices()"

              density="compact"
              class="yellowPlexi m-right-check"
              />
            </v-col>
            <v-col cols=11>
              <v-card-text>
                <v-text-field
                  v-model="searchTextDevices"

                  single-line

                  density="compact"
                  label="Filter Device"
                  append-inner-icon="mdi-filter"
                  class="filter-box"
                ></v-text-field>
              </v-card-text>
            </v-col>
          </v-row>

          <v-list-item class="items"
            v-for="device in filteredDevices" :key="device.name"
          >
            <v-row>
              <v-col cols=2>
                <v-checkbox
                v-model="device.checked"
                @click="clickDevice(device.store)"
                density="compact"
                class="yellowPlexi"
                />
              </v-col>
              <v-col cols =10>
                <div class ="subdevice">
                {{ device.name }}
                </div>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>

      </pane>

      <pane
        size="55"
      >
        <v-list
          width="100%"
        >
          <v-row>
            <v-col cols=2>
              <v-icon class="yellowPlexi add-plant" @click="seeVPP" >mdi-magnify</v-icon>
            </v-col>
            <v-col cols=8>
              <v-autocomplete
                label="Select a VPP"
                dense
                :items="VPPs"
                @update:modelValue="seeVPP"
              ></v-autocomplete>
              <!-- <v-list-item-title class="titlePane yellowPlexi" style="cursor:pointer" @click="seeVPP" > VPP: {{ vppName.name }}</v-list-item-title> -->
            </v-col>
            <v-col cols=1>

            </v-col>
          </v-row>
          <v-row>
            <v-col cols=1>
              <v-icon class="yellowPlexi add-plant" @click="addPlant" >mdi-plus-box-outline</v-icon>
            </v-col>
            <v-col cols=10>
              <v-list-item-title class="titlePane yellowPlexi"> Plants </v-list-item-title>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols=1>
              <v-checkbox
              v-model="checkedAllPlants"
              @click="clickAllPlants()"

              density="compact"
              class="yellowPlexi m-right-check"
              />
            </v-col>
            <v-col cols=11>
              <v-card-text>
                <v-text-field
                  v-model="searchTextPlants"
                  single-line
                  density="compact"
                  label="Filter Plants"
                  append-inner-icon="mdi-filter"
                  class="filter-box"
                ></v-text-field>
              </v-card-text>
            </v-col>
          </v-row>
          <v-list-item class="items"
            v-for="plant in filteredPlants"
            :key="plant.uuid"
          >
            <v-row>
              <v-col cols=2>
                <v-checkbox
                v-model="plant.checked"
                density="compact"
                class="yellowPlexi"
                />
              </v-col>
              <v-col cols =5 class="col-plant">
                <div class ="subdevice pointer" @click="clickIcon(plant.uuid)">
                <!-- {{ 'P-' + plant.uuid.substring(0, 6) }} -->
                {{ plant.name }}
                </div>
              </v-col>
              <v-col cols =5>
                <div class ="icons">
                  <v-row>
                    <!-- EV -->
                    <div v-if="plant.has_ev == 1">
                      <v-icon class="green-icon-plant" ><i @click="clickIcon(plant.uuid)" class="icon-car-solid pointer"></i></v-icon>
                    </div>
                    <div v-if="plant.has_ev == 2">
                      <v-icon class="yellow-icon-plant" ><i @click="clickIcon(plant.uuid)" class="icon-car-solid pointer"></i></v-icon>
                    </div>
                    <div v-if="plant.has_ev == 3">
                      <v-icon class="grey-icon-plant"><i @click="clickIcon(plant.uuid)" class="icon-car-solid pointer"></i></v-icon>
                    </div>
                    <!-- HP -->
                    <div v-if="plant.has_hp == 1">
                      <v-icon class="green-icon-hp"><i @click="clickIcon(plant.uuid)" class="icon-heatpump-solid-1 pointer"></i></v-icon>
                    </div>
                    <div v-if="plant.has_hp == 2">
                      <v-icon class="yellow-icon-hp"><i @click="clickIcon(plant.uuid)" class="icon-heatpump-solid-1 pointer"></i></v-icon>
                    </div>
                    <div v-if="plant.has_hp == 3">
                      <v-icon class="grey-icon-hp"><i @click="clickIcon(plant.uuid)" class="icon-heatpump-solid-1 pointer"></i></v-icon>
                    </div>
                    <!-- Battery -->
                    <div v-if="plant.has_battery == 1">
                      <v-icon class="green-icon-plant"><i @click="clickIcon(plant.uuid)" class="icon-battery-solid pointer"></i></v-icon>
                    </div>
                    <div v-if="plant.has_battery == 2">
                      <v-icon class="yellow-icon-plant"><i @click="clickIcon(plant.uuid)" class="icon-battery-solid pointer"></i></v-icon>
                    </div>
                    <div v-if="plant.has_battery == 3">
                      <v-icon class="grey-icon-plant"><i @click="clickIcon(plant.uuid)" class="icon-battery-solid pointer"></i></v-icon>
                    </div>
                    <!-- PV -->
                    <div v-if="plant.has_pv == 1">
                      <v-icon class="green-icon-plant"><i @click="clickIcon(plant.uuid)" class="icon-pv-solid pointer "></i></v-icon>
                    </div>
                    <div v-if="plant.has_pv == 2">
                      <v-icon class="yellow-icon-plant"><i @click="clickIcon(plant.uuid)" class="icon-pv-solid pointer"></i></v-icon>
                    </div>
                    <div v-if="plant.has_pv == 3">
                      <v-icon class="grey-icon-plant"><i @click="clickIcon(plant.uuid)" class="icon-pv-solid pointer"></i></v-icon>
                    </div>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>

      </pane>
    </splitpanes>
    <add-plant/>
</template>

<script>
import {
  getVPPList
} from '@/services/plants/plants'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import AddPlant from './AddPlant.vue'

export default {
  name: 'PanelPlants',

  components: {
    Splitpanes,
    Pane,
    AddPlant
  },
  props: {
    plants: {
      type: Array,
      default: () => ([])
    },

    devices: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    searchTextDevices: '',
    searchTextPlants: '',
    plantsSelected: null,
    loaded: false,
    loading: false,
    checkedAllPlants: true,
    checkedAllDevices: true,
    VPPsArray: [],
    VPPs: []
  }),
  mounted () {
    this.$emit('updatePlants')
    this.getVPPs()
  },
  computed: {
    filteredDevices () {
      const filterText = this.searchTextDevices.toLowerCase().trim()
      if (!filterText) {
        return this.devices
      } else {
        return this.devices.filter((device) =>
          device.name.toLowerCase().includes(filterText)
        )
      }
    },
    filteredPlants () {
      const filterText = this.searchTextPlants.toLowerCase().trim()
      if (!filterText) {
        return this.plants
      } else {
        return this.plants.filter((plant) =>
          plant.uuid.substring(0, 6).toLowerCase().includes(filterText)
        )
      }
    }
  },
  methods: {
    async getVPPs () {
      try {
        this.VPPsArray = await getVPPList()
        this.VPPs.push('No Selected')
        this.VPPsArray.forEach((vpp) => {
          this.VPPs.push(vpp.name)
        })
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    clickDevice (device) {
      let dv
      if (device === 'ev') {
        dv = this.$store.state.devicesSelected.ev
      } else if (device === 'hp') {
        dv = this.$store.state.devicesSelected.hp
      } else if (device === 'meter') {
        dv = this.$store.state.devicesSelected.meter
      } else if (device === 'battery') {
        dv = this.$store.state.devicesSelected.battery
      } else if (device === 'pv') {
        dv = this.$store.state.devicesSelected.pv
      }
      dv = !dv
      this.$store.dispatch('setElement', { path: `devicesSelected.${device}`, value: dv })
      this.$emit('updatePlants')
    },
    onClick () {
      this.loading = true

      setTimeout(() => {
        this.loading = false
        this.loaded = true
      }, 2000)
    },
    clickIcon (plant) {
      this.$store.dispatch('setElement', { path: 'plantSelected', value: plant })
      // this.$store.dispatch('setElement', { path: 'vppSelected.uuid', value: null })
      // this.$store.dispatch('setElement', { path: 'vppSelected.name', value: null })
      this.$store.dispatch('setElement', { path: 'tabPlants', value: 'plant' })
    },
    clickAllDevices () {
      this.$emit('clickAllDevices', !this.checkedAllDevices)
    },
    clickAllPlants () {
      this.$emit('clickAllPlants', !this.checkedAllPlants)
    },
    addPlant () {
      this.$store.dispatch('setElement', { path: 'plantRegistration.addPlantProvider', value: true })
      this.$store.dispatch('setElement', { path: 'plantRegistration.showForm', value: true })
    },
    seeVPP (value) {
      if (value !== 'No Selected') {
        const uuid = this.getUuidByName(value)
        this.$store.dispatch('setElement', { path: 'plantSelected', value: null })
        this.$store.dispatch('setElement', { path: 'vppSelected.uuid', value: uuid })
        this.$store.dispatch('setElement', { path: 'vppSelected.name', value })
        this.$store.dispatch('setElement', { path: 'tabPlants', value: 'vpp' })
      }
    },
    getUuidByName (name) {
      const selectedItem = this.VPPsArray.find(item => item.name === name)
      return selectedItem ? selectedItem.uuid : null
    }
  }

}
</script>

<style>
  .green-icon-plant{
    color: #34AE60;
    font-size: 20px!important;
    margin-right: 3px;
  }
  .grey-icon-plant{
    color:#BDC3C7;
    font-size: 20px!important;
    margin-right: 3px;
  }
  .red-icon-plant{
    color: #EC7063;
    font-size: 20px!important;
    margin-right: 3px;
  }
  .yellow-icon-plant{
    color: #dde675;
    font-size: 20px!important;
    margin-right: 3px;
  }

  .green-icon-hp{
    color: #34AE60;
    font-size: 13px!important;
    margin-left: 3px;
    margin-right: 7px;
    margin-top:5px;
  }
  .grey-icon-hp{
    color:#BDC3C7;
    font-size: 13px!important;
    margin-left: 3px;
    margin-right: 7px;
    margin-top:5px;
  }
  .red-icon-hp{
    color: #EC7063;
    font-size: 13px!important;
    margin-left: 3px;
    margin-right: 7px;
    margin-top:5px;
  }
  .yellow-icon-hp{
    color: #dde675;
    font-size: 13px!important;
    margin-left: 3px;
    margin-right: 7px;
    margin-top:5px;
  }

.splitpanes__pane {
  display: flex;
  justify-content: center;
  height: 93vh;

  color:#f4c020!important;
  font-size: 1em;
}
  .titlePane{
    font-size: 20px;
    text-align: center;
  }
   .items{
    font-size: 10px;
    text-align: center;
  }
.subdevice{

  font-size: 15px;
  text-align: left;
}
.pointer{
  cursor:pointer;
}
.col-plant{
    margin-right: -25px;
}
.v-checkbox .v-selection-control {
    min-height: var(--v-input-control-height);
    margin-top: -10px;
    margin-bottom: -16px;
}
.search-box{
  margin-top:15px;
  margin-bottom:-10px;
}
.filter-box{
  margin-top: 15px;
  margin-bottom:-25px;
  margin-left: 18px;
}
.icons{
  margin-top: 11px;
  margin-left: 13px;
  margin-right: -15px;
}
.m-right-check{
  margin-top:15px;
  margin-left:16px;
}
.add-plant{
  margin-left:17px;
  color:#f4c020!important;
}
</style>
