<template>
  <v-form ref="form" validate-on="submit" @submit.prevent="submit" class="BMSForm">
    <v-row>
      <v-col cols="12">
        <v-text-field
          density="comfortable"
          v-model="power"
          required
          label="Power (kW)"
          class="input-field"
          :rules="[rules.required, rules.decimal]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          density="comfortable"
          v-model="time"
          required
          label="Time (min)"
          class="input-field"
          :rules="[rules.required, rules.integer]"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-radio-group v-model="mode" density="compact" inline class="mode-battery">
          <v-radio label="Discharge" value="discharge"></v-radio>
          <v-radio label="Charge" value="charge"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          type="submit"
          variant="tonal"
          required
          block
          class="button-send"
          :loading="dialog"
        >
          Send
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
    <div class="text-center">
      <v-dialog
        v-model="sendDialog"
        width="500"
      >
        <v-card>
          <v-card>
            <div class="text-center">
              <!-- <v-icon size="48" class="icon-dialog">mdi-information-outline</v-icon> -->
            </div>
          </v-card>

          <v-divider></v-divider>

          <v-card class="text-center">
            {{ resultCommand}}
          </v-card>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              variant="tonal"
              text
              @click="closeDialog"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

</template>

<script>
import { sendVPPCommandBattery } from '@/services/plants/plants'
export default {
  name: 'TabPlantVppControlForm',

  components: {

  },
  props: {

  },
  data: () => ({
    power: null,
    time: null,
    mode: 'discharge',
    charge: false,
    discharge: false,
    sendDialog: false,
    dialog: false,
    rules: {
      required: (value) => !!value || 'Field is required',
      decimal: (value) =>
        /^[+-]?([0-9]*[,])?[0-9]*\.?[0-9]{0,3}$/.test(value) ||
          'Enter a number with up to 3 decimal places',
      integer: (value) => /^[0-9]+$/.test(value) || 'Enter a valid integer'
    },
    resultCommand: null

  }),
  mounted () {
  },
  methods: {
    async submit (event) {
      const { valid } = await this.$refs.form.validate()

      if (valid) {
        const uuidVpp = this.$store.state.vppSelected.uuid
        const sendCommand = await sendVPPCommandBattery(uuidVpp, this.mode, (this.power * 1000), this.time)
        this.resultCommand = sendCommand
        // console.log(sendCommand)
        // if (sendCommand.success === 'Batch command executed') {
        //   this.$store.dispatch('setElement', { path: 'plantPowerFlow.calculating', value: true })
        //   this.resultCommand = sendCommand.success + '. In the next 5 minutes the data will be refresh.'
        // } else {
        //   this.$store.dispatch('setElement', { path: 'plantPowerFlow.calculating', value: false })
        //   this.resultCommand = sendCommand.error + '. Something went wrong. Review the data or contact with the admin'
        // }
        this.dialog = true
        setTimeout(() => (this.sendDialog = true), 1000)
      }
    },

    closeDialog () {
      this.$store.dispatch('setElement', { path: 'controlBatManual', value: true })
      this.sendDialog = false
      this.dialog = false
      this.$store.dispatch('setElement', { path: 'showFormControlBatManual', value: false })
    }
  }
}
</script>

<style scoped>
.v-info-title{
  font-size: 17px;
  margin-top:-15px;
}
.v-info-text{
  margin-top:2px!important;
}

.v-info-combo{
  margin-top:-10px!important;
  margin-bottom: -20px;
}

.card-info{
  margin-top:15px;
}
.info{
  margin-top: 10px;
}
.row-info{
  margin-bottom: -16px;
}
.BMSForm{
  padding: 20px;

}
.icon-dialog{
  padding: 40px;
margin-top: 0px;
}
.input-field{
  height: 70px;
  color:azure;
  margin-bottom: -20px;
}
.mode-battery{
  color:azure;
  margin-top: 15px;
}
.button-send{
    margin-top: -5px;
}
</style>
