<template>
  <v-icon :class="iconClass"><i class="icon-heatpump-solid-1 icon-heat-pump-pf"></i></v-icon>
</template>
<script>

export default {
  name: 'TabPlantPowerFlowHeatPump',
  props: {
    iconClass: {
      type: String,
      default: 'grey'
    }
  },
  data: () => ({
  })
}
</script>
<style>
@import '../../../assets/main.css';

</style>
