<template>
  <v-icon :class="iconClass"><i class="icon-heatpump-solid"></i></v-icon>
</template>
<script>

export default {
  name: 'TabPlantPowerFlowHome',
  props: {
    iconClass: {
      type: String,
      default: 'grey'
    }
  },
  data: () => ({
  })
}
</script>
<style>
@import '../../../assets/main.css';
</style>
