<template>
  <v-row class="selector-energy">
    <v-col
      cols="2"
      class="py-2 height20"
    >
    </v-col>
    <v-col
      cols="5"
      class="py-2 height20"
    >
      <v-btn-toggle
        v-model="toggle_exclusive"
        color="yellow">
        <v-btn @click="selectorClick(toggle_exclusive)" class="labels-selector">
          <v-icon>mdi-calendar-today</v-icon>
          Day
        </v-btn>
        <v-btn @click="selectorClick(toggle_exclusive)" class="labels-selector">
          <v-icon>mdi-calendar</v-icon>
          Month
        </v-btn>
        <v-btn @click="selectorClick(toggle_exclusive)" class="labels-selector">
          <v-icon>mdi-calendar</v-icon>
          Year
        </v-btn>
        <v-btn @click="selectorClick(toggle_exclusive)" class="labels-selector">
          <v-icon>mdi-calendar</v-icon>
          Lifetime
        </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col
      cols="5"
      class="height20"
    >
      <div>
        <tab-plant-details-plot-dates>
        </tab-plant-details-plot-dates>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import TabPlantDetailsPlotDates from './TabPlantDetailsPlotDates.vue'
import getDate from '@/mixins/getDate'
export default {
  name: 'TabPlantDetailsPlotSelector',

  components: {
    TabPlantDetailsPlotDates
  },
  mixins: [getDate],
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    toggle_exclusive: 0,
    startDate: new Date().toISOString().substr(0, 10),
    endDate: null,
    menu2: false
  }),

  computed: {

  },

  watch: {
  },
  mounted () {

  },
  methods: {
    selectorClick (value) {
      const date = new Date()
      const currentDate = this.getDate()
      const currentYear = date.getFullYear()
      const currentMonth = this.getDateMonth()
      const lastDayMonth = this.getLastDayMonth()
      switch (value) {
        case 0:
          // DAY
          this.$store.dispatch('setElement', { path: 'energyPlot.agregate', value: '5m' })
          this.$store.dispatch('setElement', { path: 'energyPlot.timeStart', value: `${currentDate}T00:00:00Z` })
          this.$store.dispatch('setElement', { path: 'energyPlot.timeEnd', value: `${currentDate}T23:59:00Z` })
          this.$store.dispatch('setElement', { path: 'energyPlot.typePlot', value: 'scatter' })
          this.$store.dispatch('setElement', { path: 'energyPlot.period', value: 'day' })

          break
        case 1:
          // MONTH
          this.$store.dispatch('setElement', { path: 'energyPlot.agregate', value: '1d' })
          this.$store.dispatch('setElement', { path: 'energyPlot.timeStart', value: `${currentYear}-${currentMonth}-01T00:00:00Z` })
          this.$store.dispatch('setElement', { path: 'energyPlot.timeEnd', value: `${currentYear}-${currentMonth}-${lastDayMonth}T23:59:00Z` })
          this.$store.dispatch('setElement', { path: 'energyPlot.typePlot', value: 'bar' })
          this.$store.dispatch('setElement', { path: 'energyPlot.period', value: 'month' })
          break
        case 2:
          // YEAR
          this.$store.dispatch('setElement', { path: 'energyPlot.agregate', value: '1mo' })
          this.$store.dispatch('setElement', { path: 'energyPlot.timeStart', value: `${currentYear}-01-01T00:00:00Z` })
          this.$store.dispatch('setElement', { path: 'energyPlot.timeEnd', value: `${currentYear}-12-31T23:59:00Z` })
          this.$store.dispatch('setElement', { path: 'energyPlot.typePlot', value: 'bar' })
          this.$store.dispatch('setElement', { path: 'energyPlot.period', value: 'year' })
          break
        case 3:
          // TIMELIFE
          this.$store.dispatch('setElement', { path: 'energyPlot.agregate', value: '1y' })
          this.$store.dispatch('setElement', { path: 'energyPlot.timeStart', value: '2020-01-01T00:00:00Z' })
          this.$store.dispatch('setElement', { path: 'energyPlot.timeEnd', value: '2050-12-31T23:59:00Z' })
          this.$store.dispatch('setElement', { path: 'energyPlot.typePlot', value: 'bar' })
          this.$store.dispatch('setElement', { path: 'energyPlot.period', value: 'lifetime' })
          break
        default:
          this.$store.dispatch('setElement', { path: 'energyPlot.period', value: 'day' })
      }
    },

    getDateMonth () {
      // 2023-05-02T00:00:00Z `/measurement/series/${plant}`
      const date = new Date()
      let month = date.getMonth() + 1 // (January gives 0)
      if (month.toString.length === 2) {
        // month = month
      } else {
        month = '0' + month
      }
      return month
    },
    getLastDayMonth () {
      // 2023-05-02T00:00:00Z `/measurement/series/${plant}`
      const date = new Date()
      const month = date.getMonth() // (January gives 0)
      let lastDay = new Date(date.getFullYear(), month + 1, 0)
      lastDay = lastDay.getDate()
      return lastDay
    }

  }
}
</script>

<style scoped>
.v-btn-group--density-default.v-btn-group {
    height: 25px;
}
.selector-energy{
  margin-top:-13px;
  margin-bottom: -35px;

}
.labels-selector{
  font-size: 11px;
}
</style>
