<template>
  <v-icon :class="iconClass"><i class="icon-battery-solid"></i></v-icon>
</template>
<script>

export default {
  name: 'TabPlantPowerFlowBattery.',
  props: {
    iconClass: {
      type: String,
      default: 'grey'
    }
  },
  data: () => ({
  })
}
</script>
<style >
@import '../../../assets/main.css';
</style>
