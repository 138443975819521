<template>
  <v-card width="100%" class = "control-BMS">
    <v-switch
      v-model="controlBMSd"
      label="Control BMS"
      color="success"
      hide-details
      @click="clickBMS"
    ></v-switch>
    <v-switch
      v-model="controlBatManuald"
      label="Control Battery"
      color="success"
      hide-details
      @click="clickBatManual"

    ></v-switch>
        <v-switch
      v-model="controlInventer"
      label="Control Inverter"
      color="success"
      hide-details

    ></v-switch>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TabPlantDetailsControlBMS',

  components: {

  },
  props: {

  },
  data: () => ({
    controlBMSd: true,
    controlBatManuald: null,
    controlInventer: false

  }),

  computed: {
    ...mapState({
      controlBMS: (state) => state.controlBMS,
      controlBatManual: (state) => state.controlBatManual
    })
  },
  watch: {
    controlBMS () {
      this.controlBMSd = this.$store.state.controlBMS
    },
    controlBatManual () {
      this.controlBatManuald = this.$store.state.controlBatManual
    }
  },
  mounted () {
  },
  methods: {
    clickBatManual () {
      this.$store.dispatch('setElement', { path: 'showFormControlBatManual', value: !this.controlBatManuald })
      this.$store.dispatch('setElement', { path: 'controlBatManual', value: !this.controlBatManuald })
    },
    clickBMS () {
      this.$store.dispatch('setElement', { path: 'controlBMS', value: !this.controlBMSd })
    }
  }
}
</script>

<style>
.v-info-title{
  font-size: 17px;
  margin-top:-15px;
}
.v-info-text{
  margin-top:2px!important;
}

.v-info-combo{
  margin-top:-10px!important;
  margin-bottom: -20px;
}

.card-info{
  margin-top:15px;
}
.info{
  margin-top: 10px;
}
.row-info{
  margin-bottom: -16px;
}
.control-BMS{
  margin-top:5px;
}
</style>
