import { plexiRequest } from '.'

export const getInfo = async () => {
  return plexiRequest({ url: 'auth/user-info/' })
}

export const getInfoAdmin = async () => {
  return plexiRequest({ url: 'auth/user-admin/' })
}

export const add = async (firstName, lastName, email, username, department) => {
  const body = new window.FormData()
  body.append('email', email)
  body.append('first_name', firstName)
  body.append('last_name', lastName)
  body.append('username', username)
  body.append('department', department)

  return firstName && lastName && email && username && department &&
  plexiRequest({
    url: 'auth/user-admin/',
    method: 'post',
    data: body
  })
}

export const update = async (firstName, lastName, email, username, department, password) => {
  const body = new window.FormData()
  body.append('email', email)
  body.append('first_name', firstName)
  body.append('last_name', lastName)
  body.append('username', username)
  body.append('department', department)
  body.append('password', password)

  return firstName && lastName && email && username && department && password &&
  plexiRequest({
    url: 'auth/user-info/',
    method: 'post',
    data: body
  })
}

export const resetPassword = async (email) => {
  const body = new window.FormData()
  body.append('email', email)

  return email &&
  plexiRequest({
    url: 'auth/user-admin/',
    method: 'patch',
    data: body
  })
}

export const changePassword = async (oldPassword, newPassword, repeatPassword) => {
  const body = new window.FormData()
  body.append('old_password', oldPassword)
  body.append('new_password', newPassword)
  body.append('repeat_new_password', repeatPassword)

  return oldPassword && newPassword && repeatPassword &&
  plexiRequest({
    url: 'auth/user-info',
    method: 'patch',
    data: body
  })
}

export const remove = async (email) => {
  const body = new window.FormData()
  body.append('email', email)
  return email &&
  plexiRequest({
    url: 'auth/user-admin',
    method: 'delete',
    data: body
  })
}
