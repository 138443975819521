import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/views/Login.vue'
import Plants from '@/views/Plants.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/plants',
    name: 'Plants',
    component: Plants
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
