<template>
  <div class="scroll-vertical-tab-devices">
    <v-table density="compact" >
      <tbody>
        <tr class="online">
          <td>House consumption Online</td>
          <td>{{ dataOnline.loadPower.value[dataOnline.loadPower.value.length - 1].toFixed(2).toString().replace('.', ',') }} kW</td>
        </tr>
        <tr class="online"
          v-for="(item, index) in dataDevicesDetails"
          :key="index"
        >
          <td>{{item.frontend_name}}</td>
          <td>{{item.value + ' ' + item.unit}} </td>
        </tr>
        <!-- <tr>
          <td>Contracted Power</td>
          <td>5,75</td>
        </tr> -->
      </tbody>
    </v-table>
  </div>
</template>

<script>

export default {
  name: 'DevicesHome',

  components: {
  },
  props: {
    dataDevices: {
      type: Object,
      default: () => ({})
    },
    dataDevicesDetails: {
      type: Object,
      default: () => ({})
    },
    dataOnline: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({

  }),

  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style>

</style>
