<template>
    <div class="text-center">
      <v-dialog
        v-model="showForm"
        persistent
        width="60vw"
        class="add-plant-form"
      >
        <v-card class="card-form">
          <v-card-title class="yellowPlexi">Add new plants</v-card-title>
          <add-plant-provider v-if="form==1"
          :providers="providers"/>
          <add-plant-credentials v-if="form==2"
          :accounts="accounts"
          @getAccounts="getAccounts"/>
          <add-plant-selected v-if="form==3"
          :plants-availables="plantsAvailables"/>
        </v-card>
      </v-dialog>
    </div>

</template>

<script>
import { mapState } from 'vuex'
import AddPlantProvider from './AddPlantProvider.vue'
import AddPlantCredentials from './AddPlantCredentials.vue'
import AddPlantSelected from './AddPlantSelected.vue'

export default {
  name: 'AddPlant',

  components: {
    AddPlantProvider,
    AddPlantCredentials,
    AddPlantSelected

  },
  props: {

  },
  data: () => ({

    showForm: false,
    form: null,
    providers: [],
    accounts: [],
    plantsAvailables: []

  }),
  mounted () {
    this.getProviders()
  },

  computed: {
    ...mapState({
      showFormStore: (state) => state.plantRegistration.showForm,
      addPlantProvider: (state) => state.plantRegistration.addPlantProvider,
      addPlantCredentials: (state) => state.plantRegistration.addPlantCredentials,
      addPlantSelected: (state) => state.plantRegistration.addPlantSelected,
      addPlantImported: (state) => state.plantRegistration.addPlantImported
    })
  },
  watch: {
    // whenever question changes, this function will run
    showFormStore () {
      if (!this.$store.state.plantRegistration.showForm) {
        this.showForm = false
        this.$store.dispatch('setElement', { path: 'plantRegistration.addPlantProvider', value: false })
        this.$store.dispatch('setElement', { path: 'plantRegistration.addPlantCredentials', value: false })
        this.$store.dispatch('setElement', { path: 'plantRegistration.addPlantSelected', value: false })
        this.$store.dispatch('setElement', { path: 'plantRegistration.addPlantImported', value: false })
      }
    },
    addPlantProvider () {
      if (this.$store.state.plantRegistration.addPlantProvider) {
        this.showForm = true
        this.form = 1
      }
    },
    addPlantCredentials () {
      if (this.$store.state.plantRegistration.addPlantCredentials) {
        this.getAccounts()
        this.showForm = true
        this.form = 2
      }
    },
    addPlantSelected () {
      if (this.$store.state.plantRegistration.addPlantSelected) {
        this.getPlants()
        this.showForm = true
        this.form = 3
      }
    },
    addPlantImported () {

    }

  },
  methods: {
    getProviders () {
      this.providers = [
        {
          uuid: 'adjfhadksadskfa',
          brand: 'Huawey',
          image: 'img/huawey.jpg',
          authentication: 'user-password'
        },
        {
          uuid: 'adsfadsfwererer',
          brand: 'LG',
          image: 'img/lg.jpg',
          authentication: 'user-password'
        }
      ]
    },
    getPlants () {
      this.plantsAvailables = [
        {
          capacity: 5.005, // 3
          contactMethod: null,
          contactPerson: null,
          gridConnectionDate: '2021-05-20T00:00:00+01:00', // 5
          latitude: '39.907135',
          longitude: '-6.142612',
          plantAddress: 'C/ Conventin de Valdedios 52, 5', // 4 SIMBOLO
          plantCode: 'NE=34097064', // 2
          plantName: '7022748F' // 1
        },
        {
          capacity: 10.0,
          contactMethod: '650316044',
          contactPerson: 'FERNANDO ALONSO MEJÍA LONDOÑO',
          gridConnectionDate: '2021-05-20T00:00:00+01:00',
          latitude: '40.572500',
          longitude: '-3.605278',
          plantAddress: 'EspañaSan Sebastián de los ReyesCalle de las GramíneasCalle de las Gramíneas, 15',
          plantCode: 'NE=34097666',
          plantName: 'Y5357677W'
        },
        {
          capacity: 3.185,
          contactMethod: '699749048',
          contactPerson: 'BORYS SOROKOVIKOV  ',
          gridConnectionDate: '2021-05-21T00:00:00+01:00',
          latitude: '37.254879',
          longitude: '-3.595224',
          plantAddress: 'Calle Granada 40, Güevejar (Granada)',
          plantCode: 'NE=34098188',
          plantName: 'Y4999978E'
        }
      ]
      for (let i = 0; i < this.plantsAvailables.length; i++) {
        this.plantsAvailables[i].checked = true
      }
    },
    getAccounts () {
      console.log('getAccounts')
      this.accounts = [
        {
          api_name: 'test01',
          api_user: 'test_usr01',
          version: 1.0,
          is_active: true,
          created_at: 1697718095673,
          uuid: 'd222eca4-e71b-48ef-bd0a-d6fdc50c90e3' // NO METER
        },
        {
          api_name: 'test02',
          api_user: 'test_usr01',
          version: 1.0,
          is_active: true,
          created_at: 1697718095673,
          uuid: '444630ea-e705-475c-b9b0-0ffd7bfe25b0'
        },
        {
          api_name: 'test03',
          api_user: 'qweqweewq',
          version: 1.0,
          is_active: true,
          created_at: 1697718095673,
          uuid: '7931c3ab-b611-49e1-824e-7e83bd9f817d'
        }
      ]
    }
  }
}
</script>

<style scoped>
.card-form{
  padding: 10px;
}
</style>
