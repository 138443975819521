<template>
  <div v-if ="loadingSelf.isVisible">
    <CMLoader
      :loading="loadingSelf.isVisible"
      :message="loadingSelf.message"
    />
  </div>
  <splitpanes v-else-if="loadingSelf.isVisible == false">
      <pane
        min-size="18"
        max-size="18"
        size="18"
      >
        <panel-plants
          v-if="allPlants"
          :plants="plants"
          :devices="devices"
          @updatePlants="updatePlants"
          @clickAllDevices="clickAllDevices"
          @clickAllPlants="clickAllPlants"
        />
      </pane>
    <pane>
      <tab-plants
        :coordinates="coordinates"
        :data="plants"
        />
    </pane>
  </splitpanes>
</template>

<script>

import PanelPlants from '@/components/Plants/PanelPlants.vue'
import { getPlants } from '@/services/plants/plants'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import TabPlants from '@/components/Plants/TabPlants.vue'
import CMLoader from '@/components/Common/CMLoader.vue'

export default {
  name: 'PlantsMap',
  // emits: ['updatePlants', 'clickAllDevices', 'clickAllPlants'],
  // setup () {},
  components: {
    Splitpanes,
    Pane,
    PanelPlants,
    TabPlants,
    CMLoader
  },

  data: () => ({
    coordinates: [40.40048958037617, -3.6783988212195466],
    allPlants: null,
    plants: [],
    devices: [],
    error: false,
    loadingSelf: {
      isVisible: null,
      message: 'Loading Plants'
    }
  }),

  mounted () {
    this.showLoading(true)
    this.getData()
  },

  methods: {
    async getData () {
      try {
        const plants = await getPlants()
        this.allPlants = plants
        this.getDevices(plants)
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    getDevices (plants) {
      this.devices = []
      const EVsObj = {}
      const HPsObj = {}
      // const MettersObj = {}
      const BatteriesObj = {}
      const PVsObj = {}
      const EVs = []
      const HPs = []
      // const Metters = []
      const Batteries = []
      const PVs = []
      let ev
      let hp
      // let metter
      let battery
      let pv

      plants.forEach((plant) => {
        if (plant.status !== 3) {
          if (plant.has_ev) {
            ev = 'EV ' + plant.name
            EVs.push(ev)
          }
          if (plant.has_hp) {
            hp = 'HP ' + plant.name
            HPs.push(hp)
          }
          // if (plant.has_metter) {
          //   metter = 'Metter ' + plant.name
          //   Metters.push(metter)
          // }

          if (plant.has_battery) {
            battery = 'Battery ' + plant.name
            Batteries.push(battery)
          }
          if (plant.has_pv) {
            pv = 'PV ' + plant.name
            PVs.push(pv)
          }
        }
      })
      if (ev !== undefined) {
        EVsObj.name = 'Electric Vehicles'
        EVsObj.store = 'ev'
        EVsObj.checked = true
        EVsObj.values = EVs
        this.devices.push(EVsObj)
      }
      if (hp !== undefined) {
        HPsObj.name = 'Heat Pumps'
        HPsObj.store = 'hp'
        HPsObj.checked = true
        HPsObj.values = HPs
        this.devices.push(HPsObj)
      }
      // if (metter !== undefined) {
      //   MettersObj.name = 'Meters'
      //   MettersObj.store = 'meter'
      //   MettersObj.values = Metters
      //   MettersObj.checked = true
      //   this.devices.push(MettersObj)
      // }
      if (battery !== undefined) {
        BatteriesObj.name = 'Batteries'
        BatteriesObj.store = 'battery'
        BatteriesObj.checked = true
        BatteriesObj.values = Batteries
        this.devices.push(BatteriesObj)
      }
      if (pv !== undefined) {
        PVsObj.name = 'Solar Panels'
        PVsObj.store = 'pv'
        PVsObj.checked = true
        PVsObj.values = PVs
        this.devices.push(PVsObj)
      }
      setTimeout(() => { this.showLoading(false) }, 1000)
    },
    updatePlants () {
      try {
        const ev = this.$store.state.devicesSelected.ev
        const hp = this.$store.state.devicesSelected.hp
        const meter = this.$store.state.devicesSelected.meter
        const battery = this.$store.state.devicesSelected.battery
        const pv = this.$store.state.devicesSelected.pv

        this.getPlantsFilters(ev, hp, meter, battery, pv)
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    getPlantsFilters (ev, hp, meter, battery, pv) {
      try {
        this.plants = []

        for (let i = 0; i < this.allPlants.length; i++) {
          if (this.allPlants[i].status !== 3) {
            const fila = this.allPlants[i]
            let conditionTrue = false

            if (ev && fila.has_ev > 0) {
              conditionTrue = true
            } else if (hp && fila.has_hp > 0) {
              conditionTrue = true
            } else if (meter && fila.has_metter > 0) {
              conditionTrue = true
            } else if (battery && fila.has_battery > 0) {
              conditionTrue = true
            } else if (pv && fila.has_pv > 0) {
              conditionTrue = true
            }

            if (conditionTrue) {
              fila.checked = true
              this.plants.push(fila)
            }
          }
        }
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    clickAllDevices (checkedAllDevices) {
      checkedAllDevices ? this.showLoading(true) : this.showLoading(false)

      this.updateDevicesStore(checkedAllDevices)
      for (let i = 0; i < this.devices.length; i++) {
        this.devices[i].checked = checkedAllDevices
      }
      this.updatePlants()
      setTimeout(() => { this.showLoading(false) }, 1000)
    },

    updateDevicesStore (checkedAllDevices) {
      this.$store.dispatch('setElement', { path: 'devicesSelected.battery', value: checkedAllDevices })
      this.$store.dispatch('setElement', { path: 'devicesSelected.ev', value: checkedAllDevices })
      this.$store.dispatch('setElement', { path: 'devicesSelected.hp', value: checkedAllDevices })
      this.$store.dispatch('setElement', { path: 'devicesSelected.pv', value: checkedAllDevices })
    },
    clickAllPlants (checkedAllPlants) {
      for (let i = 0; i < this.plants.length; i++) {
        this.plants[i].checked = checkedAllPlants
      }
    },
    showLoading (show) {
      this.loadingSelf.isVisible = show
    }

  }
}
</script>

<style  >
.login {
  padding: 2rem;
}
.title {
  text-align: center;
  font-size: 24px;
  color: #f4c025;
}
.form {
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25%;
  min-width: 350px;
  max-width: 100%;
  background: #1e1e1e;
  border-radius: 5px;
  padding: 40px;
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.3);
}

.error {
  margin: 1rem 0 0;
  color: #ff4a96;
}
.msg {
  margin-top: 3rem;
  text-align: center;
}
.centerPlexi{
  margin-left: 34%;
  margin-bottom: 15px;
}
.splitpanes__pane {
  display: flex;
  justify-content: center;

  color: #333;
  font-size: 1em;
}
.splitpanes--vertical > .splitpanes__splitter {
  background-color: #333;
  position: relative;
  width: 2px;
  border-right: 2px solid rgba(238, 238, 238, 0.2);
}
.splitpanes--horizontal > .splitpanes__splitter {
  background-color: #333;
  position: relative;
  height:2px;
  border-top:2px solid rgba(238, 238, 238, 0.2);
  margin-top:-1px;
  }
</style>
