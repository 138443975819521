<template>

  <div class="plot">
    <div>
      <tab-plant-details-plot-selector>
      </tab-plant-details-plot-selector>
    </div>
      <div v-if ="loadingPlot">
        <CMLoader
          :loading="true"
        />
      </div>
    <VuePlotly
      v-else-if="loadingPlot == false"
      :data="getDataPlot"
      :layout="getLayoutPlot"
      :display-mode-bar="false"
      :displaylogo="false"
    >
    </VuePlotly>
  </div>
</template>

<script>
import { VuePlotly } from 'vue3-plotly'
import TabPlantDetailsPlotSelector from './TabPlantDetailsPlotSelector.vue'
import CMLoader from '@/components/Common/CMLoader.vue'
export default {
  name: 'TabPlantDetailsPlotEnergy',

  components: {
    VuePlotly,
    TabPlantDetailsPlotSelector,
    CMLoader
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    loadingPlot: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    heightPlot: null
  }),

  computed: {
    getDataPlot () {
      // Variables for Plot

      const datos = []
      let measurement
      let visibleMes
      const proxyData = new Proxy(this.data, {
        // Handler del proxyData
        get (target, prop) {
          return target[prop]
        }
      })
      // Obtener las claves del proxyData
      const measurements = Object.keys(proxyData) // También puedes usar Reflect.ownKeys(proxyData)

      // Recorrer las claves y acceder a los valores correspondientes
      measurements.forEach((measur) => {
        if (proxyData[measur].frontend_name === 'Battery SOC') {
          visibleMes = true

          measurement = {
            x: proxyData[measur].time,
            y: proxyData[measur].value,
            type: this.$store.state.energyPlot.typePlot,
            mode: 'lines',
            name: proxyData[measur].frontend_name,
            line: {
              shape: 'hvh'
            },
            visible: visibleMes
          }
          datos.push(measurement)
        }
      })
      return datos
    },
    getLayoutPlot () {
      const layout = {
        title: '',
        legend: {
          orientation: 'h',
          entrywidth: 200,
          valign: 'top',
          yanchor: 'bottom',
          y: 1.02,
          xanchor: 'right',
          x: 1,
          bgcolor: '#272727',
          font: {
            size: '12',
            family: 'Faktum Light'
          }

        },
        height: this.heightPlot,
        margin: {
          l: 40,
          r: 20,
          b: 65,
          t: 20,
          pad: 5
        },
        xaxis: {
          title: 'Time (h)',
          linecolor: '#fff',
          nticks: 10,
          gridcolor: '#595959',
          showspikes: true,
          spikethickness: 2,
          spikedash: 'dot',
          spikecolor: '#fff',
          spikemode: 'across',
          tickangle: 0,
          tickfont: {
            size: 12
          }
        },
        yaxis: {
          title: '(%)',
          linecolor: '#fff',
          gridcolor: '#595959'
        },
        paper_bgcolor: '#272727',
        plot_bgcolor: '#272727',
        font: {
          color: '#fff',
          family: 'Faktum Light'
        },
        hovermode: 'x',
        modebar: {
          activecolor: '#f4c020',
          color: '#fff',
          remove: ['zoomin2d', 'zoomout2d', 'resetscale2d']
        }
      }

      return layout
    }
  },

  watch: {
  },
  mounted () {
    this.heightPlot = window.innerHeight * 0.45
  },
  methods: {
    clickTabEnergy () {

    }
  }
}
</script>

<style>
.plot{
  margin-top: 5px;

}

</style>
