<template>
  <div class="scroll-vertical-tab-devices">
    <v-table density="compact">
      <tbody>
        <!-- <tr class="online">
          <td>Solar Panel Production Online</td>
          <td>{{ dataOnline.pvPower.value[dataOnline.pvPower.value.length - 1].toFixed(2).toString().replace('.', ',') }} kW</td>
        </tr> -->
        <tr class="online">
          <td>{{ dataDevicesDetails.pvPower.frontend_name }}</td>
          <td>{{ dataDevicesDetails.pvPower.value + ' ' + dataDevicesDetails.pvPower.unit}}</td>
        </tr>
        <tr class="online">
          <td>{{ dataDevicesDetails.pv1_i.frontend_name }}</td>
          <td>{{ dataDevicesDetails.pv1_i.value + ' ' + dataDevicesDetails.pv1_i.unit}}</td>
        </tr>
        <tr class="online">
          <td>{{ dataDevicesDetails.pv1_u.frontend_name }}</td>
          <td>{{ dataDevicesDetails.pv1_u.value + ' ' + dataDevicesDetails.pv1_u.unit}}</td>
        </tr>
        <tr class="online">
          <td>{{ dataDevicesDetails.p2_i.frontend_name }}</td>
          <td>{{ dataDevicesDetails.p2_i.value + ' ' + dataDevicesDetails.p2_i.unit}}</td>
        </tr>
        <tr class="online">
          <td>{{ dataDevicesDetails.pv2_u.frontend_name }}</td>
          <td>{{ dataDevicesDetails.pv2_u.value + ' ' + dataDevicesDetails.pv2_u.unit}}</td>
        </tr>

        <tr class="static-data" v-if="dataDevices.devices.inverter.length>0">
          <td>Brand</td>
          <td v-if="dataDevices">{{ dataDevices.devices.inverter[0].brand}}</td>
        </tr>
        <tr class="static-data" v-if="dataDevices.devices.inverter.length>0">
          <td>Model</td>
          <td v-if="dataDevices">{{ dataDevices.devices.inverter[0].model}}</td>
        </tr>
        <tr class="static-data" v-if="dataDevices.devices.inverter.length>0">
          <td>Rated Power</td>
          <td v-if="dataDevices">{{ dataDevices.devices.inverter[0].rated_power}} kW</td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script>

export default {
  name: 'DevicesSolarPanel',

  components: {
  },
  props: {
    dataDevices: {
      type: Object,
      default: () => ({})
    },
    dataDevicesDetails: {
      type: Object,
      default: () => ({})
    },
    dataOnline: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({

  }),

  computed: {

  },
  mounted () {
    // console.log(this.dataDevices)
  },
  methods: {

  }
}
</script>

<style>

</style>
