<template>

<span class ="title yellowPlexi"> <b>Plant Name: {{plantName}} </b></span>
<v-divider></v-divider>
<div v-if ="loadingSelf.isVisible">
  <CMLoader
    :loading="loadingSelf.isVisible"
    :message="loadingSelf.message"
  />
</div>
<splitpanes
  v-else-if="loadingSelf.isVisible == false"
  horizontal
  :push-other-panes="false"
  style="height: 88vh">
  <pane
    min-size="40"
    max-size="40"
    size="40"
    >
    <splitpanes :push-other-panes="false">
      <pane
        min-size="20"
        max-size="20"
        size="20"
      >
        <tab-plant-summary
          v-if="plantSummary"
          :data="plantSummary"
        >
        </tab-plant-summary>
      </pane>
      <pane
        min-size="30"
        max-size="30"
        size="30"
      >
        <tab-plant-power-flow
          v-if="plant && plantPlotdata"
          :data-devices="plant"
          :data-power-flow="plantPlotdata"
          :id-svg-power-flow="idSvgPowerFlow"
          :svg-power-flow="svgPowerFlow"
        ></tab-plant-power-flow>
      </pane>
      <pane
        min-size="50"
        max-size="50"
        size="50"
      >
        <tab-plant-details
          v-if="plant"
          :data-devices="plant"
          :data-devices-details="plantDataDevices"
          :data-power-flow="plantPlotdata"
        ></tab-plant-details>
      </pane>
    </splitpanes>
  </pane>
  <pane
    min-size="60"
    max-size="60"
    size="60"
    >
    <tab-plant-details-plot
    v-if="plantPlotdata"
    :data-plot-energy="plantPlotdata"
    :data-plot-savings="plantPlotSavings"
    :loading-plot="loadingPlot"
    >
    </tab-plant-details-plot>
  </pane>
</splitpanes>

</template>

<script>

import {
  getPlantsDetails, getPlantsDetailsPlot, getPlantsSummary,
  getDeviceDetailsBattery, getDeviceDetailsInverter, getDeviceDetailsMeter
} from '@/services/plants/plants'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import { mapState } from 'vuex'
import TabPlantDetails from '@/components/Plants/TabPlantDetails/TabPlantDetails.vue'
import TabPlantSummary from './TabPlantSummary/TabPlantSummary.vue'
import TabPlantDetailsPlot from './TabPlantDetails/TabPlantDetailsPlot.vue'
import TabPlantPowerFlow from './TabPlantPowerFlow/TabPlantPowerFlow.vue'
import getDate from '@/mixins/getDate'
import CMLoader from '@/components/Common/CMLoader.vue'

export default {
  name: 'PlantManagement',
  components: {
    Splitpanes,
    Pane,
    TabPlantDetails,
    TabPlantSummary,
    TabPlantDetailsPlot,
    TabPlantPowerFlow,
    CMLoader

  },
  mixins: [getDate],

  data: () => ({
    plant: null,
    plantDataDevices: {},
    plantPlotdata: null,
    plantPlotSavings: null,
    plantName: null,
    plantSummary: null,
    loadingSelf: {
      isVisible: false,
      message: 'Loading Data'
    },
    loadingPlot: false,
    timerId: null,
    svgPowerFlow: 'power-flow-plant-svg',
    idSvgPowerFlow: 'power-flow-plant-svg-id'
  }),

  mounted () {
    if (this.$store.state.tabPlants === 'plant') {
      this.showLoading(true)
      this.getData(this.$store.state.plantSelected)
      this.getDataDevices(this.$store.state.plantSelected)
      const currentDay = this.getDate()
      this.$store.dispatch('setElement', { path: 'energyPlot.agregate', value: '5m' })
      this.$store.dispatch('setElement', { path: 'energyPlot.timeStart', value: `${currentDay}T00:00:00Z` })
      this.$store.dispatch('setElement', { path: 'energyPlot.timeEnd', value: `${currentDay}T23:59:00Z` })
      this.$store.dispatch('setElement', { path: 'energyPlot.period', value: 'day' })
      setTimeout(() => { this.showLoading(false) }, 1000)
      this.timerId = this.startTimerUpdate(() => {})
    }
  },
  beforeUnmount () {
    this.stopTimer(this.timerId)
  },
  computed: {
    ...mapState({
      plantSelected: (state) => state.plantSelected,
      period: (state) => state.energyPlot.period
    })
  },
  watch: {

    // whenever question changes, this function will run
    plantSelected () {
      if (this.$store.state.tabPlants === 'plant') {
        this.showLoading(true)
        this.setDateToday()
        this.getData(this.$store.state.plantSelected)
        this.getDataDevices(this.$store.state.plantSelected)
        this.getDataPlot()
        this.getDataSavings()
        this.getDataSummary()
        setTimeout(() => { this.showLoading(false) }, 300)
      }
    },
    period () {
      if (this.$store.state.tabPlants === 'plant') {
        this.getDataPlot()
        this.getDataSavings()
        this.getDataSummary()
      }
    }
  },

  methods: {
    async getData (plantSelected) {
      try {
        const plant = await getPlantsDetails(plantSelected)
        this.plantName = plant.name
        this.plant = plant
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    async getDataDevices (plantSelected) {
      try {
        this.plantDataDevices.battery = await getDeviceDetailsBattery(plantSelected)
        this.plantDataDevices.inverter = await getDeviceDetailsInverter(plantSelected)
        this.plantDataDevices.meter = await getDeviceDetailsMeter(plantSelected)
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    async getDataPlot () {
      try {
        this.loadingPlot = true
        const plantSelected = this.$store.state.plantSelected
        const timeStart = this.$store.state.energyPlot.timeStart
        const timeEnd = this.$store.state.energyPlot.timeEnd
        const agregate = this.$store.state.energyPlot.agregate

        const timeClient = this.getLocalHourFormated()

        const plantPlotdata = await getPlantsDetailsPlot(plantSelected, timeStart, timeEnd, agregate, timeClient)
        setTimeout(() => { this.$store.dispatch('setElement', { path: 'plantPowerFlow.changeData', value: true }) }, 800)
        this.$store.dispatch('setElement', { path: 'plantPowerFlow.calculating', value: false })
        this.plantPlotdata = plantPlotdata
        this.$store.dispatch('setElement', { path: 'energyPlot.period', value: 'recustom' })
        setTimeout(() => { this.loadingPlot = false }, 300)
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    async getDataSavings () {
      try {
        // const plantSelected = this.$store.state.plantSelected
        // const plantPlotSavings = await getPlantsDetailsPlotSavings(plantSelected)
        // const plantPlotSavings = await getPlantsDetailsPlot(plantSelected)
        // this.plantPlotSavings = plantPlotSavings
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    async getDataSummary () {
      try {
        const plantSelected = this.$store.state.plantSelected
        const plantSummary = await getPlantsSummary(plantSelected)
        this.plantSummary = plantSummary
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    showLoading (show) {
      this.loadingSelf.isVisible = show
    },
    getLocalHourFormated () {
      const dateObject = new Date()
      const year = dateObject.getFullYear()
      const month = String(dateObject.getMonth() + 1).padStart(2, '0')
      const day = String(dateObject.getDate()).padStart(2, '0')
      const hours = String(dateObject.getHours()).padStart(2, '0')
      const minutes = String(dateObject.getMinutes()).padStart(2, '0')
      const seconds = String(dateObject.getSeconds()).padStart(2, '0')

      // Crear la cadena en formato ISO8601
      const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`

      return isoString
    },
    setDateToday () {
      const todayStart = this.getLocalHourFormated().substr(0, 10)
      this.$store.dispatch('setElement', { path: 'energyPlot.timeStart', value: todayStart + 'T00:00:00Z' })
      this.$store.dispatch('setElement', { path: 'energyPlot.timeEnd', value: todayStart + 'T23:59:00Z' })
    },
    startTimerUpdate (callback, interval = 300000) {
      const timerId = setInterval(() => {
        this.showLoading(true)
        this.setDateToday()
        this.getData(this.$store.state.plantSelected)
        this.getDataDevices(this.$store.state.plantSelected)
        this.getDataPlot()
        this.getDataSavings()
        this.getDataSummary()
        setTimeout(() => { this.showLoading(false) }, 300)
        if (callback) {
          callback()
        }
      }, interval)

      return timerId
    },
    stopTimer (timerId) {
      clearInterval(timerId)
    }

  }
}
</script>

<style scoped>
.login {
  padding: 2rem;
}
.title {
  text-align: center;
  font-size: 18px;
    display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 4px;
}
.form {
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  min-width: 350px;
  max-width: 100%;
  background: #1e1e1e;
  border-radius: 5px;
  padding: 40px;
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.3);
}

.error {
  margin: 1rem 0 0;
  color: #ff4a96;
}
.msg {
  margin-top: 3rem;
  text-align: center;
}
.centerPlexi{
  margin-left: 34%;
  margin-bottom: 15px;
}
.splitpanes__pane {
  display: flex;
  justify-content: center;

  color: rgba(255, 255, 255, 0.6);
  font-size: 1em;
}

</style>
