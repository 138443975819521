<template>
  <div>
    <div class="title-power-flow"><span>Power flow</span></div>
    <div class="last-updated"><span>Updated: {{lastUpdated}}</span></div>
    <div class="power-flow" :id="svgPowerFlow">
      <div id="iconCar" class="icon-car">
        <tab-plant-power-flow-car
          :iconClass="getCar"
        >
        </tab-plant-power-flow-car>
      </div>
      <div id="iconHeatpump" class="icon-heatpump">
        <tab-plant-power-flow-heat-pump
          :iconClass="getHeatPump"
        >
        </tab-plant-power-flow-heat-pump>
      </div>
      <div id="iconHome" class="icon-home">
        <tab-plant-power-flow-home
          :iconClass="getHome"
        >
        </tab-plant-power-flow-home>
      </div>
      <div id="iconHub" class="icon-hub">
        <tab-plant-power-flow-hub
          :iconClass="getHub"
        >
        </tab-plant-power-flow-hub>
      </div>
      <div id="iconTower" class="icon-tower">
        <tab-plant-power-flow-tower
          :iconClass="getTower"
        >
        </tab-plant-power-flow-tower>
      </div>
      <div id="iconBattery" class="icon-battery">
        <tab-plant-power-flow-battery
          :iconClass="getBattery"
        >
        </tab-plant-power-flow-battery>
      </div>
      <div id="iconSolarPanel" class="icon-solar-panel">
        <tab-plant-power-flow-solar-panel
          :iconClass="getSolarPanel"
        >
        </tab-plant-power-flow-solar-panel>
      </div>
    </div>
  </div>
</template>

<script>

import TabPlantPowerFlowCar from '../TabPlantPowerFlow/TabPlantPowerFlowCar.vue'
import TabPlantPowerFlowHeatPump from '../TabPlantPowerFlow/TabPlantPowerFlowHeatPump.vue'
import TabPlantPowerFlowHome from '../TabPlantPowerFlow/TabPlantPowerFlowHome.vue'
import TabPlantPowerFlowHub from '../TabPlantPowerFlow/TabPlantPowerFlowHub.vue'
import TabPlantPowerFlowTower from '../TabPlantPowerFlow/TabPlantPowerFlowTower.vue'
import TabPlantPowerFlowBattery from '../TabPlantPowerFlow/TabPlantPowerFlowBattery.vue'
import TabPlantPowerFlowSolarPanel from '../TabPlantPowerFlow/TabPlantPowerFlowSolarPanel.vue'
import { mapState } from 'vuex'

export default {
  name: 'TabPlantPowerFlow',

  components: {
    TabPlantPowerFlowCar,
    TabPlantPowerFlowHeatPump,
    TabPlantPowerFlowHome,
    TabPlantPowerFlowHub,
    TabPlantPowerFlowTower,
    TabPlantPowerFlowBattery,
    TabPlantPowerFlowSolarPanel
  },
  props: {
    dataDevices: {
      type: Object,
      default: () => ({})
    },
    dataPowerFlow: {
      type: Object,
      default: () => ({})
    },
    idSvgPowerFlow: {
      type: String,
      default: ''
    },
    svgPowerFlow: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    lastUpdated: null
  }),
  computed: {
    ...mapState({
      plantPowerFlowShow: (state) => state.plantPowerFlow.show,
      plantPowerFlowChangeData: (state) => state.plantPowerFlow.changeData
    }),

    getCar () {
      let car
      if (this.dataDevices.has_ev === 0) {
        car = 'no-device'
      } else if (this.dataDevices.has_ev === 1) {
        car = 'green'
      } else if (this.dataDevices.has_ev === 2) {
        car = 'yellow'
      } else if (this.dataDevices.has_ev === 3) {
        car = 'grey'
      }
      return car
    },
    getHeatPump () {
      let heatpump
      if (this.dataDevices.has_hp === 0) {
        heatpump = 'no-device'
      } else if (this.dataDevices.has_hp === 1) {
        heatpump = 'green'
      } else if (this.dataDevices.has_hp === 2) {
        heatpump = 'yellow'
      } else if (this.dataDevices.has_hp === 3) {
        heatpump = 'grey'
      }

      return heatpump
    },
    getHome () {
      let home = 'grey'
      if (this.dataPowerFlow.loadPower !== undefined) {
        const valueHome = this.dataPowerFlow.loadPower.value[this.dataPowerFlow.loadPower.value.length - 1].toFixed(2)
        if (valueHome > 0) {
          home = 'green'
        } else {
          home = 'grey'
        }
      }
      return home
    },
    getHub () {
      const hub = 'blue'
      return hub
    },
    getTower () {
      let tower
      if (this.dataPowerFlow.meterPower !== undefined) {
        const valueGrid = this.dataPowerFlow.meterPower.value[this.dataPowerFlow.meterPower.value.length - 1].toFixed(2)
        if (valueGrid > 0) {
          tower = 'green'
        } else if (valueGrid < 0) {
          tower = 'green'
        } else {
          tower = 'grey'
        }
      }
      return tower
    },
    getBattery () {
      let battery

      if (this.dataDevices.has_battery === 0) {
        battery = 'no-device'
      } else if (this.dataDevices.has_battery === 1) {
        battery = 'green'
      } else if (this.dataDevices.has_battery === 2) {
        battery = 'yellow'
      } else if (this.dataDevices.has_battery === 3) {
        battery = 'grey'
      }

      return battery
    },
    getSolarPanel () {
      let solarPanel
      if (this.dataDevices.has_pv === 0) {
        solarPanel = 'no-device'
      } else if (this.dataDevices.has_pv === 1) {
        solarPanel = 'green'
      } else if (this.dataDevices.has_pv === 2) {
        solarPanel = 'yellow'
      } else if (this.dataDevices.has_pv === 3) {
        solarPanel = 'grey'
      }
      return solarPanel
    }

  },
  watch: {
    // whenever question changes, this function will run
    plantPowerFlowShow () {
      this.drawPowerFlow()
    },
    plantPowerFlowChangeData: {

      handler (newVal, oldVal) {
        // Realiza acciones adicionales según sea necesario
        this.drawPowerFlow()
      },
      deep: true // Indica que se debe observar de manera profunda cualquier cambio dentro del objeto
    }
  },
  mounted () {
  },

  methods: {
    drawPowerFlow () {
      // lastUpdated
      if (this.dataPowerFlow.loadPower !== undefined) {
        this.lastUpdated = this.dataPowerFlow.loadPower.time[this.dataPowerFlow.loadPower.time.length - 1].slice(-9)
      } else {
        this.lastUpdated = '-'
      }

      // start draw
      const d3 = require('d3')

      if ((document.getElementById(this.idSvgPowerFlow) != null)) {
        document.getElementById(this.idSvgPowerFlow).remove()
      }

      const div = d3.select(`#${this.svgPowerFlow}`)
      const divPos = div.node().getBoundingClientRect()

      const svg = div.append('svg')
        .attr('width', divPos.width)
        .attr('height', divPos.height)
        .attr('class', 'power-flow-car-svg-class')
        .attr('id', this.idSvgPowerFlow)

      // CAR
      const lineCarPos = [[135, 82], [135, 20]]
      const lineCar = d3.line()(lineCarPos)
      const classLineCar = 'power-flow-line-class'
      if (this.dataDevices.has_ev > 0) {
        svg.append('path')
          .attr('d', lineCar)
          .attr('class', classLineCar)
      }

      // HEAT PUMP
      const lineHeatpumpPos = [[117, 89], [48, 32]]
      const lineHeatpump = d3.line()(lineHeatpumpPos)
      const classlineHeatpump = 'power-flow-line-class'
      if (this.dataDevices.has_hp > 0) {
        svg.append('path')
          .attr('d', lineHeatpump)
          .attr('class', classlineHeatpump)
      }

      // HOME
      const lineHomePos = [[110, 105], [18, 105]]
      const lineHome = d3.line()(lineHomePos)
      let classlineHome
      let classHome
      if (this.dataPowerFlow.loadPower !== undefined) {
        const valueHome = this.dataPowerFlow.loadPower.value[this.dataPowerFlow.loadPower.value.length - 1].toFixed(2)
        if (valueHome > 0) {
          classlineHome = 'animation-positive'
          classHome = 'value-positive'
        } else {
          classlineHome = 'power-flow-line-class'
          classHome = 'value-zero'
        }
        svg.append('path')
          .attr('d', lineHome)
          .attr('class', classlineHome)
        svg.append('text')
          .attr('x', 55) // Position x
          .attr('y', 90) // Position y
          .attr('text-anchor', 'middle') // Align text to center
          .attr('class', classHome)
          .text(valueHome.toString().replace('.', ',') + ' kW')
      }

      // BATTERY
      const lineBatteryPos = [[119, 123], [50, 165]]
      const lineBattery = d3.line()(lineBatteryPos)
      let classlineBattery
      let classBattery

      if (this.dataDevices.has_battery > 0) {
        if (this.dataPowerFlow.batteryPower !== undefined) {
          const valueBattery = this.dataPowerFlow.batteryPower.value[this.dataPowerFlow.batteryPower.value.length - 1].toFixed(2)
          let socBattery, unitSoc
          if (this.dataPowerFlow.batterySOC !== undefined) {
            socBattery = this.dataPowerFlow.batterySOC.value[this.dataPowerFlow.batterySOC.value.length - 1].toFixed(0)
            unitSoc = ' %'
          } else {
            socBattery = ''
            unitSoc = ''
          }

          if (valueBattery > 0) {
            classlineBattery = 'animation-positive'
            classBattery = 'value-positive'
          } else if (valueBattery < 0) {
            classlineBattery = 'animation-negative'
            classBattery = 'value-negative'
          } else {
            classlineBattery = 'power-flow-line-class'
            classBattery = 'value-zero'
          }
          svg.append('path')
            .attr('d', lineBattery)
            .attr('class', classlineBattery)
          svg.append('text')
            .attr('x', 35) // Position x
            .attr('y', 150) // Position y
            .attr('text-anchor', 'middle') // Align text to center
            .attr('class', classBattery)
            .text(valueBattery.toString().replace('.', ',') + ' kW')
          svg.append('text')
            .attr('x', 35) // Position x
            .attr('y', 210) // Position y
            .attr('text-anchor', 'middle') // Align text to center
            .attr('class', classBattery)
            .text(socBattery + unitSoc)
        }
      }

      // PV
      const lineSolarPos = [[135, 130], [135, 183]]
      const lineSolar = d3.line()(lineSolarPos)
      let classlineSolar
      let classSolar

      if (this.dataDevices.has_pv > 0) {
        if (this.dataPowerFlow.pvPower !== undefined) {
          const valuePv = this.dataPowerFlow.pvPower.value[this.dataPowerFlow.pvPower.value.length - 1].toFixed(2)
          if (valuePv > 0) {
            classlineSolar = 'animation-negative'
            classSolar = 'value-negative'
          } else if (valuePv < 0) {
            // in this case never will be
            classlineSolar = 'animation-positive'
            classSolar = 'value-positive'
          } else {
            classlineSolar = 'power-flow-line-class'
            classSolar = 'value-zero'
          }
          svg.append('path')
            .attr('d', lineSolar)
            .attr('class', classlineSolar)
          svg.append('text')
            .attr('x', 185) // Position x
            .attr('y', 180) // Position y
            .attr('text-anchor', 'middle') // Align text to center
            .attr('class', classSolar)
            .text(valuePv.toString().replace('.', ',') + ' kW')
        }
      }
      // TOWER
      const lineTowerPos = [[160, 105], [270, 105]]
      const lineTower = d3.line()(lineTowerPos)
      let classlineTower
      let classTower

      if (this.dataPowerFlow.meterPower !== undefined) {
        const valueGrid = this.dataPowerFlow.meterPower.value[this.dataPowerFlow.meterPower.value.length - 1].toFixed(2)
        if (valueGrid > 0) {
          classlineTower = 'animation-positive'
          classTower = 'value-positive'
        } else if (valueGrid < 0) {
          classlineTower = 'animation-negative'
          classTower = 'value-negative'
        } else {
          classlineTower = 'power-flow-line-class'
          classTower = 'value-zero'
        }

        svg.append('path')
          .attr('d', lineTower)
          .attr('class', classlineTower)

        svg.append('text')
          .attr('x', 215) // Position x
          .attr('y', 90) // Position y
          .attr('text-anchor', 'middle') // Align text to center
          .attr('class', classTower)
          .text(valueGrid.toString().replace('.', ',') + ' kW')
      }
      this.$store.dispatch('setElement', { path: 'plantPowerFlow.changeData', value: false })
    }

  }
}
</script>
<style>
  .power-flow{
    padding: 20px;
    width:100%;
    height: 100%;
  }
  .title-power-flow{
    display: flex;
    justify-content: center;
    margin-top: 5px;
    font-size: 17px;
  }
  .last-updated{
    display: flex;
    justify-content: right;
    margin-top: -17px;
    margin-bottom: -20px;
    margin-right: 55px;
    font-size: 11px;
  }
  .icon-car{
    display: flex;
    justify-content: center;
    font-size:50px!important;
  }
  .icon-heatpump{
    margin-left: -220px;
    margin-top: -30px; /* Centrar verticalmente el icono */
    margin-bottom: auto;
    font-size:50px!important;
  }
  .icon-home{
    margin-left: -280px;
    margin-top: 20px; /* Centrar verticalmente el icono */
    margin-bottom: auto;
    font-size:50px!important;
  }
  .icon-hub{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -50px; /* Centrar verticalmente el icono */
    margin-bottom: auto;
    font-size:50px!important;

  }
  .icon-tower{
    margin-left: 300px;
    margin-top: -50px; /* Centrar verticalmente el icono */
    margin-bottom: auto;
  }
  .icon-battery{
    margin-left: -210px;
    margin-top: 20px; /* Centrar verticalmente el icono */
    margin-bottom: -20px;
    font-size:50px!important;
  }
  .icon-solar-panel{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px; /* Centrar verticalmente el icono */
    margin-bottom: auto;
    font-size:50px!important;
  }
  .power-flow-car-svg-class{
    margin-top:-230px;
    padding-left: 60px;
  }
  .power-flow-line-class{
    stroke:#BDC3C7;
    stroke-width:3;
  }

  .power-flow-to-right {
    stroke:#34AE60;
    stroke-width:3;
    animation: moveLinear 2s linear infinite;
  }

    .animation-positive{
      stroke-width: 3px;
      stroke: #34AE60;
      /* opacity: 70%; */
      stroke-dasharray: 10;
      animation: flow-positive 1s infinite linear;
  }

  @keyframes moveLinear {
    0% {
      transform: translateY(5%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes flow-positive {
      0% {
          stroke-dashoffset: 20;
      }
      100% {
          stroke-dashoffset: 0;
      }
  }
  .animation-negative{
      stroke-width: 3px;
      stroke: #34AE60;
      /* opacity: 70%; */
      stroke-dasharray: 10;
      animation: flow-negative 1s infinite linear;
  }
  @keyframes flow-negative {
      0% {
          stroke-dashoffset: 0;
      }
      100% {
          stroke-dashoffset: 20;
      }
  }

  .value-positive{
    font-size:13px;
    fill:#34AE60;
    font-weight: bold;
  }
  .value-negative{
    font-size:13px;
    fill:#34AE60;
    font-weight: bold;
  }
  .value-zero{
    font-size:13px;
    fill:#BDC3C7;
    font-weight: bold;
  }
</style>
