<template>
  <v-icon :class="iconClass"><i class="icon-tower-electricity-solid icon-tower-pf"></i></v-icon>

</template>
<script>

export default {
  name: 'TabPlantPowerFlowTower.',
  props: {
    iconClass: {
      type: String,
      default: 'grey'
    }
  },
  data: () => ({
  })
}
</script>
<style>
@import '../../../assets/main.css';
</style>
