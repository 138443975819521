<template>
  <div class="plot">
    <VuePlotly
      :data="getDataPlot"
      :layout="getLayoutPlot"
      :display-mode-bar="false"
      :displaylogo="false"
    >
    </VuePlotly>
  </div>
</template>

<script>
import { VuePlotly } from 'vue3-plotly'
export default {
  name: 'TabPlantDetailsPlotEnergy',

  components: {
    VuePlotly
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    heightPlot: null
  }),

  computed: {
    getDataPlot () {
      // Variables for Plot
      const datos = []
      let xaxisValue = []
      let yaxisValue = []
      let measurement = {}
      let visibleMes = false

      const proxyData = new Proxy(this.data, {
        // Handler del proxyData
        get (target, prop) {
          return target[prop]
        }
      })
      // Obtener las claves del proxyData
      const savings = Object.keys(proxyData) // También puedes usar Reflect.ownKeys(proxyData)

      // I HAVE TO KNOW HOW MANY COPANIES WE HAVE
      savings.forEach((company) => {
        xaxisValue = []
        yaxisValue = []
        const proxyMonths = new Proxy(proxyData[company], {
          // Handler del proxyData
          get (target, prop) {
            return target[prop]
          }
        })
        const months = Object.keys(proxyMonths)
        months.forEach((month) => {
          xaxisValue.push(month)
          yaxisValue.push(proxyMonths[month]['Total Electricity Cost'])
        })

        visibleMes = true
        measurement = {
          x: xaxisValue,
          y: yaxisValue,
          type: 'bar',
          mode: 'lines',
          name: 'Company' + company,
          line: {
            shape: 'hvh'
          },

          visible: visibleMes
        }
        datos.push(measurement)
      })

      return datos
    },
    getLayoutPlot () {
      const layout = {
        title: '',
        legend: {
          orientation: 'h',
          entrywidth: 200,
          valign: 'top',
          yanchor: 'bottom',
          y: 1.02,
          xanchor: 'right',
          x: 1,
          bgcolor: '#272727',
          font: {
            size: '12',
            family: 'Faktum Light'
          }

        },
        height: this.heightPlot,
        margin: {
          l: 50,
          r: 20,
          b: 40,
          t: 20,
          pad: 5
        },
        xaxis: {
          title: 'Months',
          linecolor: '#fff',
          nticks: 10,
          gridcolor: '#595959',
          showspikes: true,
          spikethickness: 2,
          spikedash: 'dot',
          spikecolor: '#fff',
          spikemode: 'across',
          tickangle: 0,
          tickfont: {
            size: 12
          }
        },
        yaxis: {
          title: '$',
          linecolor: '#fff',
          gridcolor: '#595959'
        },
        paper_bgcolor: '#272727',
        plot_bgcolor: '#272727',
        font: {
          color: '#fff',
          family: 'Faktum Light'
        },
        hovermode: 'x',
        modebar: {
          activecolor: '#f4c020',
          color: '#fff',
          remove: ['zoomin2d', 'zoomout2d', 'resetscale2d']
        }
      }

      return layout
    }
  },

  watch: {
  },
  mounted () {
    this.heightPlot = window.innerHeight * 0.45
  },
  methods: {
    clickTabEnergy () {

    }
  }
}
</script>

<style>
.plot{
  margin-top: 5px;
}

</style>
