<template>
    <l-marker
      :lat-lng="location"
      @click="clickIcon(uuid)"
      :icon="getIcon"
      >
      <l-tooltip
        :content="name">
      </l-tooltip>
    </l-marker>
</template>

<script>
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { LMarker, LTooltip } from '@vue-leaflet/vue-leaflet'
export default {
  name: 'PlantIcon',

  components: {
    LMarker,
    LTooltip
  },
  props: {
    location: {
      type: Array,
      default: () => ([])
    },
    uuid: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    evStatus: {
      type: Number,
      default: 0
    },
    hpStatus: {
      type: Number,
      default: 0
    },
    batteryStatus: {
      type: Number,
      default: 0
    },
    pvStatus: {
      type: Number,
      default: 0
    }
  },
  data: () => ({

  }),

  computed: {
    getIcon () {
      const evStatus = this.getClassByStatus(this.evStatus)
      const hpStatus = this.getClassByStatus(this.hpStatus)
      const batteryStatus = this.getClassByStatus(this.batteryStatus)
      const pvStatus = this.getClassByStatus(this.pvStatus)

      const icon = L.divIcon({
        className: 'custom-icon',
        html: '<div class="square">' +
              '<div class="square-circle">' +
                '<i class="icon-battery-solid ' + batteryStatus + ' iconPlant" ></i>' +
              '</div>' +
              '<div class="square-circle">' +
                '<i class="icon-pv-solid ' + pvStatus + ' iconPlant" ></i>' +
              '</div>' +
              '<div class="square-circle">' +
                '<i class="icon-car-solid ' + evStatus + ' iconPlant" ></i>' +
              '</div>' +
              '<div class="square-circle">' +
                '<i class="icon-heatpump-solid-1 ' + hpStatus + ' icon-heat-pump" ></i>' +
              '</div>' +
            '</div>'
      })
      return icon
    }

  },
  mounted () {

  },
  methods: {
    clickIcon (plant) {
      this.$store.dispatch('setElement', { path: 'plantSelected', value: plant })
      this.$store.dispatch('setElement', { path: 'tabPlants', value: 'plant' })
    },
    getClassByStatus (status) {
      let clas
      if (status === 0) {
        clas = 'no-device'
      } else if (status === 1) {
        clas = 'green'
      } else if (status === 2) {
        clas = 'yellow'
      } else if (status === 3) {
        clas = 'grey'
      }
      return clas
    }
  }
}
</script>

<style>

</style>
