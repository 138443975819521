import axios from 'axios'
import { refreshToken } from './auth/auth'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  // baseURL: 'http://localhost:8000',
  method: 'get'
})

export const plexiRequest = async (config) => {
  try {
    if (!config.url) throw new Error('Url is not defined')

    const finalConfig = {
      method: instance.defaults.method,
      ...config,
      headers: {
        Authorization: `Bearer ${window.sessionStorage.access}`,
        ...config.headers
      }
    }
    const response = await instance.request(finalConfig) || {}
    return response.data
  } catch (err) {
    const { response = {} } = err
    const isUnauthorized = response.status === 401
    const errorServer = response.status === 500
    const refresh = window.sessionStorage.getItem('refresh')
    const logged = window.sessionStorage.getItem('logged')

    if (logged === true || logged === 'true') {
      if (isUnauthorized) {
        if (refresh !== '') { return await refreshToken(config) }
        if (refresh === '') { return window.open('/') }
      }
    }
    if (errorServer) {
      return response.status
    }
    console.error(err)

    throw new Error('Generic error')
  }
}

export default instance
