<template>
  <div class="scroll-vertical-tab-devices">
    <v-table density="compact">
      <tbody>
        <tr class="online">
          <td>Status</td>
          <td v-if="dataOnline.batteryPower.value[dataOnline.batteryPower.value.length - 1] >0">Charging</td>
          <td v-else-if="dataOnline.batteryPower.value[dataOnline.batteryPower.value.length - 1] < 0">Discharging</td>
          <td v-else-if="dataOnline.batteryPower.value[dataOnline.batteryPower.value.length - 1] == 0 ">Disconnected</td>
        </tr>
        <tr class="online">
          <td>Battery consumption Online</td>
          <td>{{ dataOnline.batteryPower.value[dataOnline.batteryPower.value.length - 1].toFixed(2).toString().replace('.', ',') }} kW</td>
        </tr>
        <tr class="online">
          <td>State of charge (SOC)</td>
          <td>{{ dataOnline.batterySOC.value[dataOnline.batterySOC.value.length - 1].toFixed(0)}} %</td>
        </tr>
        <tr class="online"
          v-for="(item, index) in dataDevicesDetails"
          :key="index"
        >
          <td>{{item.frontend_name}}</td>
          <td>{{item.value + ' ' + item.unit}} </td>
        </tr>
        <tr class="static-data" v-if="dataDevices.devices.battery.length >0">
          <td>Brand</td>
          <td>{{ dataDevices.devices.battery[0].brand}}</td>
        </tr>
        <tr class="static-data" v-if="dataDevices.devices.battery.length >0">
          <td>Model</td>
          <td>{{ dataDevices.devices.battery[0].model}}</td>
        </tr>
        <tr class="static-data" v-if="dataDevices.devices.battery.length >0">
          <td>Capacity</td>
          <td>{{ dataDevices.devices.battery[0].capacity}} kW</td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script>

export default {
  name: 'DevicesBattery',

  components: {
  },
  props: {
    dataDevices: {
      type: Object,
      default: () => ({})
    },
    dataDevicesDetails: {
      type: Object,
      default: () => ({})
    },
    dataOnline: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({

  }),

  computed: {

  },
  mounted () {
  },
  methods: {

  }
}
</script>

<style>
.scroll-vertical{
 overflow-y: scroll

}
</style>
