import { plexiRequest } from '..'

export const getPlants = async () => {
  return plexiRequest({ url: '/plant/list' })
}

export const getPlantsDetails = async (plant) => {
  return plexiRequest({ url: `/plant/list/${plant}` })
}

export const getPlantsDetailsPlot = async (plant, timeStart, timeEnd, aggregate, timeClient) => {
  return plexiRequest({ url: `/measurement/series/${plant}?TIME_START=${timeStart}&TIME_END=${timeEnd}&AGGREGATE=${aggregate}&TIME_CLIENT=${timeClient}` })
}

export const getPlantsDetailsPlotSavings = async (plant) => {
  return plexiRequest({ url: `/savings/comparator/${plant}` })
}

export const getPlantsSummary = async (plant) => {
  return plexiRequest({ url: `/measurement/summary/${plant}?PERIOD=lmonth` })
}

export const getDeviceDetailsBattery = async (plant) => {
  return plexiRequest({ url: `/measurement/series/battery/${plant}` })
}

export const getDeviceDetailsInverter = async (plant) => {
  return plexiRequest({ url: `/measurement/series/inverter/${plant}` })
}

export const getDeviceDetailsMeter = async (plant) => {
  return plexiRequest({ url: `/measurement/series/meter/${plant}` })
}
export const getVPPDetailsPlot = async (vpp, timeStart, timeEnd, aggregate, timeClient) => {
  return plexiRequest({ url: `/vpp/series/${vpp}?TIME_START=${timeStart}&TIME_END=${timeEnd}&AGGREGATE=${aggregate}&TIME_CLIENT=${timeClient}` })
}
export const getVPPSummary = async (vpp) => {
  return plexiRequest({ url: `/vpp/summary/${vpp}?PERIOD=lmonth` })
}
export const getVPPDetails = async (vpp) => {
  return plexiRequest({ url: `/vpp/list/${vpp}` })
}
export const getVPPList = async () => {
  return plexiRequest({ url: '/vpp/list/' })
}

export const sendVPPCommandBattery = async (uuid, mode, power, duration) => {
  // const body = new window.FormData()
  // body.append('MODE', mode)
  // body.append('POWER', power)
  // body.append('DURATION', duration)

  return plexiRequest({
    url: `/vpp/command/${uuid}?MODE=${mode}&POWER=${power}&DURATION=${duration}`,
    method: 'post'
    // data: body
  }).catch(error => {
    // Manejo de errores
    const errorReturn = 'Commands is being proccessed'

    console.error('Error:', error)
    return errorReturn // Puedes manejar el error según tus necesidades
  })
}
export const sendPlantCommandBattery = async (uuid, mode, power, duration) => {
  // const body = new window.FormData()
  // body.append('MODE', mode)
  // body.append('POWER', power)
  // body.append('DURATION', duration)

  return plexiRequest({
    url: `/plant/command/${uuid}?MODE=${mode}&POWER=${power}&DURATION=${duration}`,
    method: 'post'
    // data: body
  }).catch(error => {
    // Manejo de errores
    const errorReturn = 'Commands is being proccessed'

    console.error('Error:', error)
    return errorReturn // Puedes manejar el error según tus necesidades
  })
}
