export default {
  methods: {
    getDate () {
      // 2023-05-02T00:00:00Z `/measurement/series/${plant}`
      const date = new Date()
      let month = date.getMonth() + 1 // (January gives 0)
      let day
      const checkDay = date.getDate().toString().length
      if (month.toString().length === 2) {
        // month = month
      } else {
        month = '0' + month
      }
      if (checkDay === 2) {
        day = date.getDate().toString()
      } else {
        day = '0' + date.getDate().toString()
      }
      const currentDay = date.getFullYear() + '-' + month + '-' + day
      return currentDay
    }
  }
}
