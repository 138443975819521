<template>
  <div class="map">
    <l-map
      ref="map"
      :zoom="zoom"
      :max-zoom="18"
      :min-zoom="3"
      :center="coordinates"
      @update:zoom="zoomUpdate"
      @ready="onLeafletReady"
      >
        <template v-if="leafletReady">

          <l-control-layers position="bottomright"></l-control-layers>
          <l-tile-layer
            :url="url"
            :attribution="optionsLayer.attribution"
            layer-type="base"
            name="OpenStreetMap"
          >
          </l-tile-layer>
          <l-layer-group
            ref="plants"
            layer-type="overlay"
            name="plants"
          >
          <marker-cluster
            :options="{ showCoverageOnHover: false, chunkedLoading: true, disableClusteringAtZoom: 16,
             showCoverageOnHover: false, animateAddingMarkers:false, singleMarkerMode:false }"
          >
            <plant
              :zoom="zoom"
              :plants="data">
            </plant>
            </marker-cluster>
          </l-layer-group>
        </template>
    </l-map>
  </div>
</template>

<script>

import {
  LMap,
  LTileLayer,
  LControlLayers,
  LLayerGroup
} from '@vue-leaflet/vue-leaflet'

import Plant from '@/components/Map/Plant.vue'
import MarkerCluster from './MarkerCluster.vue'

export default {
  name: 'MapBase',

  components: {
    LMap,
    LTileLayer,
    LControlLayers,
    LLayerGroup,
    Plant,
    MarkerCluster
  },
  props: {
    coordinates: {
      type: Array,
      default: () => ([40.31976674799456, -3.969568458524468])
    },
    data: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?apikey=a0c50571-164e-4075-95a3-3cc37fa56dba',
    // url: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
    zoom: 6.5,
    leafletReady: false,
    leafletObject: null,

    visible: false,
    optionsLayer: [
      {
        name: 'Street Map',
        // visible: true,
        // attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
        apikey: '<your apikey>',
        opacity: 0
      }
    ]
  }),

  computed: {

  },
  mounted () {
  },
  methods: {
    async onLeafletReady () {
      await this.$nextTick()
      this.leafletObject = this.$refs.map.leafletObject
      this.leafletReady = true
    },
    zoomUpdate (zoom) {
      // console.log(zoom)
      this.zoom = zoom
    }
  }

}
</script>

<style>
.v-navigation-drawer--clipped:not(.v-navigation-drawer--temporary)
:not(.v-navigation-drawer--is-mobile) {
    z-index: 502;
}

nav.v-navigation-drawer--clipped:not(.v-navigation-drawer--temporary)
:not(.v-navigation-drawer--is-mobile) {
    z-index: 502;
}

.tooltip{
  background-color:black;
  color:white;
}
.map{

  height:88vh;
  width:83vw;
  margin-top:15px;
}
</style>
